// https://github.com/iamkun/dayjs/tree/dev/src/locale
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ru'

import dayjs, { Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import duration from 'dayjs/plugin/duration'
import isoWeek from 'dayjs/plugin/isoWeek'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { i18n, UserLocale, type LocalizedText } from './i18n'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(minMax)
dayjs.extend(isoWeek)
dayjs.extend(updateLocale)
dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat)

export const localizedDayjs = (date?: Dayjs) => (date ?? dayjs()).locale(i18n.global.locale.value)

// to construct "5m 3s" duration format
export enum DurationComponent {
  d = 'd',
  h = 'h',
  m = 'm',
  s = 's',
  ms = 'ms',
}
export const durationComponentLabel: Record<DurationComponent, LocalizedText> = {
  [DurationComponent.d]: {
    en: 'd',
    ru: ' дн',
    es: 'd', // "días" abbreviated as "d"
    de: 'Tg', // "Tage" abbreviated as "Tg"
    fr: 'j', // "jours" abbreviated as "j"
    it: 'g', // "giorni" abbreviated as "g"
  },
  [DurationComponent.h]: {
    en: 'h',
    ru: ' ч',
    es: 'h', // "horas" abbreviated as "h"
    de: 'Std', // "Stunden" abbreviated as "Std"
    fr: 'h', // "heures" abbreviated as "h"
    it: 'h', // "ore" abbreviated as "h"
  },
  [DurationComponent.m]: {
    en: 'm',
    ru: ' мин',
    es: 'm', // "minutos" abbreviated as "m"
    de: 'min', // "Minuten" abbreviated as "min"
    fr: 'min', // "minutes" abbreviated as "min"
    it: 'min', // "minuti" abbreviated as "min"
  },
  [DurationComponent.s]: {
    en: 's',
    ru: ' сек',
    es: 's', // "segundos" abbreviated as "s"
    de: 's', // "Sekunden" abbreviated as "s"
    fr: 's', // "secondes" abbreviated as "s"
    it: 's', // "secondi" abbreviated as "s"
  },
  [DurationComponent.ms]: {
    en: 'ms',
    ru: ' мс',
    es: 'ms', // "milisegundos" abbreviated as "ms"
    de: 'ms', // "Millisekunden" abbreviated as "ms"
    fr: 'ms', // "millisecondes" abbreviated as "ms"
    it: 'ms', // "millisecondi" abbreviated as "ms"
  },
}
export const localizedDurationComponent = (value: number, component: DurationComponent) =>
  `${value}${durationComponentLabel[component][i18n.global.locale.value]}`

// localized formats for specific unit
// HOUR
export const localizedHourFormats: Record<UserLocale, string> = {
  en: 'h a', // 4 pm (12-hour)
  ru: 'H:mm', // 16:00 (24-hour)
  es: 'H:mm', // 16:00 (24-hour)
  de: 'H:mm', // 16:00 (24-hour)
  fr: 'HH:mm', // 02:00 (24-hour, leading 0)
  it: 'HH:mm', // 02:00 (24-hour, leading 0)
}
export const localizedHourFormat = () => localizedHourFormats[i18n.global.locale.value]
// DAY
export const localizedDayFormats: Record<UserLocale, string> = {
  en: 'MMM D', // Sep 21
  ru: 'Do MMM', // 21 сент.
  es: 'D MMM',
  de: 'Do MMM', // 21. Sep (?)
  fr: 'D MMM',
  it: 'D MMM',
}
export const localizedDayFormat = () => localizedDayFormats[i18n.global.locale.value]
