<script setup lang="ts">
import Button from '@/components/Button.vue'
import { tipToTextMapping, type LessonTip } from '@/course/course-tips'
import type { UserLocale } from '@/plugins/i18n'
import type { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { useI18n } from 'vue-i18n'
import Emoji from './Emoji.vue'

type Props = {
  tip: LessonTip
  shortcut: KeyboardShortcut
}
defineProps<Props>()

const { t, locale } = useI18n()

const emit = defineEmits(['dismiss'])
</script>

<template>
  <div class="modal">
    <div class="content">
      <p class="title"><Emoji name="bulb" /> {{ t('title') }}</p>
      <p>{{ tipToTextMapping[tip][locale as UserLocale] }}</p>
      <div class="buttons action">
        <Button size="sm" type="primary" @click="emit('dismiss')">{{ t('continue') }}</Button>
        <!-- <div class="shortcut-hint">Or press <Shortcut :shortcut="shortcut" /></div> -->
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Pro tip"
  },
  "ru": {
    "title": "Совет профи"
  },
  "es": {
    "title": "Consejo experto"
  },
  "de": {
    "title": "Profi-Tipp"
  },
  "fr": {
    "title": "Astuce pro"
  },
  "it": {
    "title": "Suggerimento da pro"
  }
}
</i18n>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-25vh);
  z-index: 1;

  .content {
    padding: var(--s-lg);
    border-radius: var(--br-lg);
    width: 420px;
    background-color: var(--c-surface);
    border: 1px solid var(--c-secondary-border);
    font-size: var(--fz-md);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .title {
      margin-bottom: var(--s-sm);
      font-weight: 700;
      font-size: var(--fz-lg);
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: var(--s-md);

      .shortcut-hint {
        font-size: var(--fz-sm);
      }

      &.action {
        margin-top: var(--s-lg);
      }
    }
  }
}
</style>
