<script setup lang="ts">
import Button from '@/components/Button.vue'
import ToggleButton from '@/components/ToggleButton.vue'
import { useCookieStore } from '@/stores/cookieStore'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const cookieStore = useCookieStore()

const isSetupShown = ref(false)
const isAllowedAnalytics = ref(cookieStore.isAnalyticsAllowed)
</script>

<template>
  <div class="modal">
    <div v-if="!isSetupShown" class="content">
      <p class="title">{{ t('title') }}</p>
      <p>{{ t('cookieInfo') }}</p>
      <div class="buttons action">
        <Button variant="secondary" @click="isSetupShown = true">{{ t('customize') }}</Button>
        <Button type="primary" @click="cookieStore.setAnalyticsAllowed(true)">{{ t('acceptAll') }}</Button>
      </div>
    </div>
    <div v-else class="content">
      <p class="title">{{ t('customizeTitle') }}</p>
      <div class="buttons toggle">
        <ToggleButton :checked="isAllowedAnalytics" @checked="(checked) => (isAllowedAnalytics = checked)" :label="t('analyticsCookies')" />
      </div>
      <div class="buttons action">
        <Button variant="secondary" @click="isSetupShown = false">{{ t('back') }}</Button>
        <Button type="primary" @click="cookieStore.setAnalyticsAllowed(isAllowedAnalytics)">{{ t('save') }}</Button>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "We use cookies",
    "cookieInfo": "To understand how our site is used and make it better.",
    "customize": "Customize",
    "acceptAll": "Accept all",
    "customizeTitle": "Customize cookies preferences",
    "analyticsCookies": "Analytics cookies"
  },
  "ru": {
    "title": "Мы используем файлы cookie",
    "cookieInfo": "Чтобы понимать как используется наш сайт и делать его лучше.",
    "customize": "Настроить",
    "acceptAll": "Принять все",
    "customizeTitle": "Настройка файлов cookie",
    "analyticsCookies": "Файлы cookie для аналитики"
  },
  "es": {
    "title": "Utilizamos cookies",
    "cookieInfo": "Para entender cómo se utiliza nuestro sitio y mejorarlo.",
    "customize": "Personalizar",
    "acceptAll": "Aceptar todo",
    "customizeTitle": "Personalizar preferencias de cookies",
    "analyticsCookies": "Cookies analíticas"
  },
  "de": {
    "title": "Wir verwenden Cookies",
    "cookieInfo": "Um zu verstehen, wie unsere Website genutzt wird und sie zu verbessern.",
    "customize": "Anpassen",
    "acceptAll": "Alle akzeptieren",
    "customizeTitle": "Cookie-Einstellungen anpassen",
    "analyticsCookies": "Analyse-Cookies"
  },
  "fr": {
    "title": "Nous utilisons des cookies",
    "cookieInfo": "Pour comprendre comment notre site est utilisé et l'améliorer.",
    "customize": "Personnaliser",
    "acceptAll": "Tout accepter",
    "customizeTitle": "Personnaliser les préférences de cookies",
    "analyticsCookies": "Cookies analytiques"
  },
  "it": {
    "title": "Utilizziamo i cookie",
    "cookieInfo": "Per capire come viene utilizzato il nostro sito e migliorarlo.",
    "customize": "Personalizza",
    "acceptAll": "Accetta tutti",
    "customizeTitle": "Personalizza le preferenze dei cookie",
    "analyticsCookies": "Cookie analitici"
  }
}
</i18n>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000;
  padding-block: var(--s-md);
  padding-inline: var(--s-md);

  .content {
    padding: var(--s-md);
    border-radius: 10px;
    width: 320px;
    background-color: var(--c-surface);
    border: 1px solid var(--c-secondary-border);
    font-size: var(--fz-sm);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .title {
      margin-bottom: var(--s-sm);
      font-weight: 600;
      font-size: var(--fz-md);
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: var(--s-sm);

      &.toggle {
        margin-top: var(--s-sm);
      }

      &.action {
        margin-top: var(--s-lg);
      }

      .btn {
        padding-inline: var(--s-md);
        min-width: unset;
        flex: 1;
      }
    }
  }
}
</style>
