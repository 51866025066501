<script setup lang="ts">
import Keyboard from '@/components/Keyboard.vue'
import { isTypeable, type KeyCode } from '@/helpers/keyboards/KeyCode'
import { Layer } from '@/helpers/keyboards/Layer'
import { accuracyColor, pressesColor, speedColor } from '@/helpers/metric-color-scales'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { hslToString } from '@/types/color-types'
import { Metric } from '@/types/metric-types'
import Color from 'colorjs.io'
import { computed, ref, watchEffect, type StyleValue } from 'vue'

type Props = {
  metric: Metric
  lastTraining?: boolean
}
const props = withDefaults(defineProps<Props>(), { lastTraining: false })

const courseStore = useCourseStore()
const userStore = useUserStore()

const userLayout = courseStore.current

if (!userLayout) {
  throw new Error('No user layout, nothing to highight')
}

// - current layout
// - typingData (props)

// split typing data by LAYER for curr layout
// - data per key code
// - min, max, mid range

// curr layouer model with range and colors

const data = computed(() => {
  return props.lastTraining ? courseStore.current.stats.lastTraining : courseStore.current.stats.highlight
})

const dataPerKey = computed(() => {
  return data.value.perKeycode(props.metric)
})

// single num value or [min, mid, max]
const rangePerLayer = computed<Partial<Record<Layer, number[]>>>(() => {
  return Object.fromEntries(
    Object.entries(dataPerKey.value.perLayer()).map(([k, v]) => {
      const values = v.values()
      const min = Math.min(...values)
      const max = Math.max(...values)
      return [k, [min, (min + max) / 2, max]]
    }),
  )
})

const currentLayer = ref(Layer.Default)

const calcColor = (value: number, range: number[]) => {
  const [min, , max] = range

  switch (props.metric) {
    case Metric.Speed:
      return speedColor(value, min, max)
    case Metric.Accuracy:
      return hslToString(accuracyColor(value, min, max))
    case Metric.Presses:
      return pressesColor(value, min, max)
    default:
      throw new Error('Unknown metric to calc color')
  }
}

const currentLayerRange = computed(() => {
  return rangePerLayer.value[currentLayer.value]
})

const currentLayerRangeValuesModel = defineModel('rangeValues')
const currentLayerRangeColorsModel = defineModel('rangeColors')

watchEffect(() => {
  currentLayerRangeValuesModel.value = currentLayerRange.value
  currentLayerRangeColorsModel.value = currentLayerRange.value ? currentLayerRange.value.map((v, i, arr) => calcColor(v, arr)) : null
})

const keyStyleFunc = (code: KeyCode, value: string, keyboardState: Layer): StyleValue => {
  if (!isTypeable(code)) {
    return {
      color: 'transparent',
      '--c-icon': 'transparent',
    }
  }

  let resultStyle: StyleValue = {
    fontSize: 'calc(var(--keyboard-size-unit) * 3.5)',
  }

  const charData = dataPerKey.value.get(new LayeredKeyCode(code, keyboardState))
  if (!charData) {
    return resultStyle
  }

  const range = rangePerLayer.value[keyboardState]
  if (!range) {
    throw new Error('No data for whole layer')
  }

  const color = calcColor(charData, range)

  const borderColor = new Color(color)
  borderColor.alpha = borderColor.alpha > 0.3 ? borderColor.alpha / 2 : borderColor.alpha

  // const clrsides = color.split(')')
  // const clrsdies2 = clrsides[0].split(' ')
  // const currOp =
  // const clr = `${clrsdies2.slice(0, -1).join(' ')} 50%)`
  // console.log('color', color)
  // console.log('clr', clr.alpha)

  resultStyle['--background-color'] = color
  resultStyle['--border-color'] = props.metric === Metric.Accuracy ? color : borderColor.toString()
  resultStyle['--text-color'] = props.metric === Metric.Accuracy ? '#000' : 'var(--c-text-primary)'

  return {
    ...resultStyle,
  }
}
</script>

<template>
  <Keyboard :keyStyleFunc="keyStyleFunc" outlined handlePresses="layer-only" unbordered v-model:layoutState="currentLayer" />
</template>

<style lang="scss" scoped></style>
