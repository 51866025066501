import { sample } from 'lodash-es'

export const generateRandomText = (dict: string[], textLength: number) => {
  let result = ''
  const addedWords: string[] = []

  while (result.length < textLength) {
    let randomWord = sample(dict) as string
    if (!addedWords.includes(randomWord)) {
      result += randomWord + ' '
      addedWords.push(randomWord)
    }
  }

  return result.trim()
}

export const generateRandomPseudoWord = (minLen: number, maxLen: number, availableChars: string[], requiredChars: string[] = []) => {
  // Generate a random length for the word between minLen and maxLen
  const wordLength = Math.floor(Math.random() * (maxLen - minLen + 1)) + minLen

  // Initialize the word with required characters
  let word = requiredChars.join('')

  // Fill the remaining length of the word with random characters from charSet
  for (let i = requiredChars.length; i < wordLength; i++) {
    const randomChar = availableChars[Math.floor(Math.random() * availableChars.length)]
    word += randomChar
  }

  // Shuffle the word to randomize the position of required characters
  word = word
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('')

  return word
}
