<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import Button from '@/components/Button.vue'
import Emoji from '@/components/Emoji.vue'
import Keyboard from '@/components/Keyboard.vue'
import { logAnalyticsEvent } from '@/helpers/analytics'
import { filterCandidates, getDiffKeys, keyStyleStepOne, keyStyleStepTwo } from '@/helpers/keyboard-setup-helper'
import { isTypeable, typeableKeyCodes, type KeyCode, type ModifierKeyCode, type TypeableKeyCode } from '@/helpers/keyboards/KeyCode'
import { KeyboardFormat, KeyboardLayout } from '@/helpers/keyboards/KeyboardLayout'
import { Layer, isOptionLayer } from '@/helpers/keyboards/Layer'
import { KeyPressHelper } from '@/helpers/press-helper'
import { getModifierKeys } from '@/helpers/press-hint-modifiers-helper'
import { getUserLanguage } from '@/helpers/user-agent-utils'
import { allLangConfig } from '@/languages/all-lang-config'
import { type LanguageCode, type LanguageMetadata } from '@/languages/languages-config'
import { getLayoutsMetadata, layoutsConfig } from '@/layouts/layouts-config'
import { getLangTitle } from '@/plugins/i18n'
import { useAppStore } from '@/stores/appStore'
import { useCourseStore } from '@/stores/courseStore'
import { LayeredKeyCode } from '@/types/LayeredKeycode'
import { LayoutDefinition } from '@/types/LayoutDefinition'
import { OS } from '@/types/main-types'
import { sum } from 'lodash-es'
import { computed, nextTick, onBeforeMount, onMounted, onUnmounted, ref, watchEffect, type StyleValue } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t, locale } = useI18n()
const router = useRouter()
const courseStore = useCourseStore()
const appStore = useAppStore()

// overflow-hidden, scroll only inside layouts section
onBeforeMount(() => {
  document.body.classList.add('setup')
})
onUnmounted(() => {
  document.body.classList.remove('setup')
})

// live
const userLayout = ref(KeyboardLayout.template())
const currentLayer = ref<Layer>(Layer.Default)

// helpers

const userFormat = computed(() => {
  return userLayout.value.format
})

const userOS = computed(() => {
  return userLayout.value.os
})

const userLanguage = computed(() => {
  return userLayout.value.languageCode
})

const resetUserLayoutKeymap = () => {
  const os = userOS.value
  const format = userFormat.value
  const lang = userLanguage.value
  userLayout.value = KeyboardLayout.template()
  userLayout.value.os = os
  userLayout.value.format = format
  userLayout.value.languageCode = lang
}

// candidates
const allCompatibleLayouts = ref<KeyboardLayout[]>([])
const allLayouts = ref<KeyboardLayout[]>([])
const currentCandidates = ref<KeyboardLayout[]>([])

// highlights

const highlightedKey = ref<LayeredKeyCode | null>(null)

const highlightedModifiers = computed<ModifierKeyCode[]>(() => {
  if (!highlightedKey.value) return []
  return getModifierKeys(highlightedKey.value, userOS.value, true)
})

// global steps

enum SetupStep {
  Language = 'Language',
  Format = 'Format',
  Layout = 'Layout',
}

const setupStepTitles: Record<SetupStep, string> = {
  [SetupStep.Language]: t('step.language'),
  [SetupStep.Format]: t('step.format'),
  [SetupStep.Layout]: t('step.layout'),
}

const SetupStepNumbers: Record<SetupStep, number> = {
  [SetupStep.Language]: 0,
  [SetupStep.Format]: 1,
  [SetupStep.Layout]: 2,
}

const currentSetupStep = ref<SetupStep>(SetupStep.Language)

const onStepClick = (step: SetupStep) => {
  if (SetupStepNumbers[currentSetupStep.value] <= SetupStepNumbers[step]) {
    return
  }

  const lang = userLanguage.value
  userLayout.value = KeyboardLayout.template()
  if (step === SetupStep.Format) {
    userLayout.value.languageCode = lang
  }

  // cleanup state
  // 1. language
  languageFilter.value = ''
  // 3. layout
  showSecondaryLayouts.value = false
  selectedLayoutId.value = null
  allLayouts.value = []
  allCompatibleLayouts.value = []
  currentCandidates.value = []
  highlightedKey.value = null
  isAutoSetupActive.value = false
  definedAutomatically.value = false

  currentSetupStep.value = step
}

// layout auto setup steps

enum AutoSetupStep {
  Filtering = 'Filtering',
  Defined = 'Defined',
  Unsupported = 'Unsupported',
}

const autoSetupStep = computed<AutoSetupStep>(() => {
  const candidateLayoutIds = currentCandidates.value.map((c) => c.layoutId)
  const allCompatLayoutIds = allCompatibleLayouts.value.map((l) => l.layoutId)

  if (candidateLayoutIds.length && allCompatLayoutIds.every((l) => !candidateLayoutIds.includes(l))) {
    return AutoSetupStep.Unsupported
  } else if (!candidateLayoutIds.length && allLayouts.value.length) {
    return AutoSetupStep.Unsupported
  }

  if (currentCandidates.value.length === 1) {
    return AutoSetupStep.Defined
  }

  return AutoSetupStep.Filtering
})

// step 1: language

const userAgentLanguage = getUserLanguage()
const languageFilter = ref('')

const filteredLanguages = computed(() => {
  const sortFn = (a: LanguageMetadata, b: LanguageMetadata) => (a.code === userAgentLanguage ? -1 : b.code === userAgentLanguage ? 1 : 0)
  return Object.values(allLangConfig)
    .filter((l) => !languageFilter.value || getLangTitle(l.code).toLowerCase().includes(languageFilter.value.toLowerCase()))
    .sort(sortFn)
})

const continueWithLanguage = (lang: LanguageCode) => {
  logAnalyticsEvent('course_setup_language', { value: lang })
  userLayout.value.languageCode = lang
  currentSetupStep.value = SetupStep.Format
}

// step 2: format

const formatTitles: Record<KeyboardFormat, string> = {
  [KeyboardFormat.ISO]: 'ISO',
  [KeyboardFormat.ANSI]: 'ANSI',
  [KeyboardFormat.Unknown]: 'Unknown',
}

const continueWithFormat = (format: KeyboardFormat) => {
  userLayout.value.format = format
  currentSetupStep.value = SetupStep.Layout
  logAnalyticsEvent('course_setup_format_manual', { value: format })
  initCandidates()
}

// step 3: layout

const showSecondaryLayouts = ref(false)

// selected layout
const selectedLayoutId = ref<null | string>(null)
const selectedLayout = computed(() => {
  return !selectedLayoutId.value
    ? null
    : KeyboardLayout.fromLayoutDefinition(
        new LayoutDefinition(userLayout.value.os, userLayout.value.format, selectedLayoutId.value, userLayout.value.languageCode),
      )
})
const setSelectedLayout = (id: string, auto: boolean) => {
  selectedLayoutId.value = selectedLayoutId.value === id ? null : id
  isAutoSetupActive.value = auto
  definedAutomatically.value = auto
}

const isAutoSetupActive = ref(false)
const definedAutomatically = ref(false)

const layoutGroups = computed(() => {
  const filterOut = (allLayouts: KeyboardLayout[], toFilterOut: KeyboardLayout[]) =>
    allLayouts.filter((l) => !toFilterOut.some((ll) => ll.layoutId === l.layoutId))

  let layouts = allCompatibleLayouts.value

  const primaryLayouts = layouts.filter((l) => l.primaryLanguage === userLayout.value.languageCode)
  layouts = filterOut(layouts, primaryLayouts)

  const secondaryLayouts = layouts.filter((l) => l.primaryLanguage !== userLayout.value.languageCode)

  return {
    primaryLayouts,
    secondaryLayouts,
  }
})

const continueWithLayout = (layoutId: string) => {
  const definedLayout = allCompatibleLayouts.value.find((l) => l.layoutId === layoutId)
  if (!definedLayout) {
    return
  }
  const definition = new LayoutDefinition(definedLayout.os, definedLayout.format, definedLayout.layoutId, userLayout.value.languageCode)
  courseStore.addLayout(definedLayout, definition)
  logAnalyticsEvent(definedAutomatically.value ? 'course_setup_layout_auto' : 'course_setup_layout_manual', { layout_id: layoutId })
  router.push({ name: 'home' })
}

watchEffect(() => {
  if (currentCandidates.value.length === 1) {
    const layoutId = currentCandidates.value[0].layoutId

    selectedLayoutId.value = layoutId
    if (layoutGroups.value.secondaryLayouts.find((l) => l.layoutId === layoutId)) {
      showSecondaryLayouts.value = true
    }

    isAutoSetupActive.value = false
    definedAutomatically.value = true

    // scroll to defined layout
    nextTick(() => {
      const element = document.getElementById(`layout-${layoutId}`)
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    })
  }
})

const startAutoDefinition = () => {
  resetUserLayoutKeymap()
  initCandidates()
  selectedLayoutId.value = null
  isAutoSetupActive.value = true
}

// press handling

const pressHelper = new KeyPressHelper(userLayout.value)

const candidatesFilteringHandler = (event: KeyboardEvent) => {
  const keyCode = pressHelper.getConsistentKeyCode(event)

  const char =
    isTypeable(keyCode) &&
    userLayout.value.os === OS.win &&
    isOptionLayer(currentLayer.value) &&
    // here we check if AltGr char is same as without AltGr, which practically means in real life such a press will produce nothing
    // TODO: ideally we dynamically verify this knowledge (AltGr layer can't contain same char as without AltGr)
    userLayout.value.getChar(keyCode, currentLayer.value - 2) === event.key
      ? ''
      : event.key

  // handle AltGr
  if (userOS.value !== OS.mac && event.code === 'AltRight') {
    if (event.key !== 'AltGraph') {
      // means AltGr not supported
      currentCandidates.value = currentCandidates.value.filter((c) => c.supportsOptionLayer === false)
      updateCandidats()
      return
    } else if (highlightedKey.value?.keyCode === 'AltGraph') {
      // this situation means AltGr supported and it was exact press we expected
      userLayout.value._supportsOptionLayer = true
      updateCandidats()
      return
    }
  }

  if (!typeableKeyCodes.includes(keyCode as TypeableKeyCode) || !currentLayer.value) {
    return
  }

  if (highlightedKey.value?.layer !== currentLayer.value) {
    appStore.showToast('cursor', { text: t('pressAllHighlightedKeys'), emoji: 'keyboard' })
    return
  }

  if (highlightedKey.value?.keyCode !== keyCode) {
    return
  }

  userLayout.value.addKeyValue(currentLayer.value, char, keyCode as TypeableKeyCode)
  updateCandidats()
}

const keyDownListener = (event: KeyboardEvent) => {
  if (!isAutoSetupActive.value) {
    return
  }
  return candidatesFilteringHandler(event)
}

const initCandidates = () => {
  if (userFormat.value === KeyboardFormat.Unknown) {
    return
  }
  const layouts = Object.keys(getLayoutsMetadata(userOS.value, locale.value)).map((layoutId) => {
    const l = KeyboardLayout.fromLayoutDefinition(new LayoutDefinition(userOS.value, userFormat.value, layoutId, 'en'))
    l.languageCode = l.primaryLanguage
    return l
  })

  allCompatibleLayouts.value = layouts.filter((l) => l.supportsLanguage(userLayout.value.languageCode).supports)
  allLayouts.value = layouts
  currentCandidates.value = layouts

  resetUserLayoutKeymap()
  updateCandidats()
}

const updateCandidats = () => {
  const filtered = filterCandidates(userLayout.value, currentCandidates.value)

  // more layouts popular first
  const layoutIds = Object.keys(layoutsConfig[userLayout.value.os])
  currentCandidates.value = filtered.sort((a, b) => layoutIds.indexOf(a.layoutId) - layoutIds.indexOf(b.layoutId))

  // primary language first
  currentCandidates.value = filtered.sort((a, b) => {
    const aPrimary = a.primaryLanguage === userLayout.value.languageCode
    const bPrimary = b.primaryLanguage === userLayout.value.languageCode

    if (aPrimary && !bPrimary) return -1
    if (bPrimary && !aPrimary) return 1
    return 0
  })

  const filteredLayouts = Object.values(filtered)

  highlightedKey.value = getDiffKeys(userLayout.value, filteredLayouts, true)

  // default to first layout if several exactly same
  // example: "mac_standard" and "mac_us_international_pc" (only dead diffs)
  if (!highlightedKey.value && currentCandidates.value.length > 1) {
    currentCandidates.value = currentCandidates.value.slice(0, 1)
  }

  // NOTE: if needed, catch here rare case with only dead keys diff
  // // // filter out truly unique candidates (could be non-unique by dead keys)
  // let uniqueCandidates = []

  // for (const candidate of filteredLayouts) {
  //   let isUnique = true
  //   for (const uniqueCandidate of uniqueCandidates) {
  //     if (candidate.isEqual(uniqueCandidate)) {
  //       isUnique = false
  //       break
  //     }
  //   }
  //   if (isUnique) {
  //     uniqueCandidates.push(candidate)
  //   }
  // }

  // // if more than 1 truly unique candidates, munual selection needed
  // if (uniqueCandidates.length > 1) {
  //   console.log('Dead keys diffs, manual selection')
  //   logAnalyticsEvent(analyticsEvent.autoKeyboardSetupFail('diffs by dead keys'))
  //   step.value = 3
  //   return
  // }
}

onMounted(() => {
  window.addEventListener('keydown', keyDownListener)
})
onUnmounted(() => {
  window.removeEventListener('keydown', keyDownListener)
})

// helpers

const keyStyleFunc = (code: KeyCode, value: string, keyboardState: Layer): StyleValue => {
  if (userFormat.value === KeyboardFormat.Unknown) {
    return keyStyleStepOne(code)
  }
  if (autoSetupStep.value == AutoSetupStep.Defined) {
    return {}
  }

  return keyStyleStepTwo(code, value, highlightedModifiers.value, highlightedKey.value, currentLayer.value, userLayout.value)
}

// handle mouse click on keyboad
const keyboardClickHandler = (event: MouseEvent) => {
  if (autoSetupStep.value === AutoSetupStep.Filtering) {
    appStore.showToast('cursor', { text: t('useKeyboardToPresKeys'), emoji: 'keyboard' })
  }
}
</script>

<template>
  <teleport to="#app-header">
    <AppHeader>
      <template #middle>
        <div class="steps">
          <div
            class="step"
            :class="{ active: currentSetupStep == step, completed: SetupStepNumbers[currentSetupStep] > SetupStepNumbers[step] }"
            v-for="(title, step, index) in setupStepTitles"
            :key="index"
            @click="onStepClick(step)"
          >
            <div class="indicator">{{ index + 1 }}</div>
            <div class="title">
              {{ title }}
              <div class="value" v-if="SetupStepNumbers[currentSetupStep] > SetupStepNumbers[step]">
                {{ step == SetupStep.Language ? getLangTitle(allLangConfig[userLayout.languageCode].code) : formatTitles[userLayout.format] }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </AppHeader>
  </teleport>

  <div class="view-wrapper">
    <div class="setup-wrapper lang" v-if="currentSetupStep === SetupStep.Language">
      <div class="setup-header">
        <div class="title">{{ t('selectTypingLanguage') }}</div>
      </div>
      <div class="setup-content lang">
        <input type="text" v-model="languageFilter" :placeholder="t('search')" />
        <div class="languages">
          <div class="language" @click="continueWithLanguage(language.code)" v-for="language in filteredLanguages" :key="language.code">
            <span><Emoji :name="`flag-${language.code}`" /></span>
            <span>&nbsp;&nbsp;</span>
            <span>{{ getLangTitle(language.code) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="setup-wrapper format" v-else-if="currentSetupStep === SetupStep.Format">
      <div class="setup-header">
        <div class="title">{{ t('howEnterLooks') }}</div>
      </div>
      <div class="setup-content">
        <div class="formats-wrapper" v-if="currentSetupStep === SetupStep.Format">
          <div @click="continueWithFormat(KeyboardFormat.ANSI)" class="format-option">
            <svg viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                class="enter-border"
                d="M1 81C1 77.6863 3.68629 75 7 75H123C126.314 75 129 77.6863 129 81V135C129 138.314 126.314 141 123 141H7C3.68629 141 1 138.314 1 135V81Z"
                stroke-width="2"
              />
              <path
                class="enter-icon"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.685 99C108.959 99 108.37 99.583 108.37 100.302V110.069C108.37 111.747 106.996 113.108 105.301 113.108H91.4899L97.614 107.043C98.1276 106.535 98.1276 105.71 97.614 105.202C97.1004 104.693 96.2678 104.693 95.7542 105.202L87.3852 113.489C86.8716 113.998 86.8716 114.822 87.3852 115.331L95.7542 123.619C96.2678 124.127 97.1004 124.127 97.614 123.619C98.1276 123.11 98.1276 122.285 97.614 121.777L91.4899 115.712H105.301C108.449 115.712 111 113.186 111 110.069V100.302C111 99.583 110.411 99 109.685 99Z"
              />
              <path
                class="secondary"
                d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z"
                stroke-width="2"
              />
              <path
                class="secondary"
                d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V61C129 64.3137 126.314 67 123 67H67C63.6863 67 61 64.3137 61 61V7Z"
                stroke-width="2"
              />
            </svg>
            <div class="caption">{{ t('horizontal') }}</div>
          </div>
          <div @click="continueWithFormat(KeyboardFormat.ISO)" class="format-option">
            <svg viewBox="0 0 130 142" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                class="enter-border"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M61 7C61 3.68629 63.6863 1 67 1H123C126.314 1 129 3.68629 129 7V135C129 138.314 126.314 141 123 141H86.9052C83.5915 141 80.9052 138.314 80.9052 135V71.2105C80.9052 67.8968 78.2189 65.2105 74.9052 65.2105H67C63.6863 65.2105 61 62.5242 61 59.2105V7Z"
                stroke-width="2"
              />
              <path
                class="enter-icon"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M109.685 33C108.959 33 108.37 33.583 108.37 34.3023V44.0693C108.37 45.7475 106.996 47.1079 105.301 47.1079H91.4899L97.614 41.0434C98.1276 40.5349 98.1276 39.7103 97.614 39.2017C97.1004 38.6932 96.2678 38.6932 95.7542 39.2017L87.3852 47.4893C86.8716 47.9979 86.8716 48.8224 87.3852 49.331L95.7542 57.6186C96.2678 58.1271 97.1004 58.1271 97.614 57.6186C98.1276 57.11 98.1276 56.2855 97.614 55.7769L91.4899 49.7124H105.301C108.449 49.7124 111 47.1859 111 44.0693V34.3023C111 33.583 110.411 33 109.685 33Z"
              />
              <path
                class="secondary"
                d="M1 7C1 3.68629 3.68629 1 7 1H43C46.3137 1 49 3.68629 49 7V61C49 64.3137 46.3137 67 43 67H7C3.68629 67 1 64.3137 1 61V7Z"
                stroke-width="2"
              />
              <path
                class="secondary"
                d="M1 81C1 77.6863 3.68629 75 7 75H63C66.3137 75 69 77.6863 69 81V135C69 138.314 66.3137 141 63 141H7C3.68629 141 1 138.314 1 135V81Z"
                stroke-width="2"
              />
            </svg>
            <div class="caption">{{ t('vertical') }}</div>
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="setup-wrapper keyb">
        <div class="setup-header">
          <div class="title shifted" v-if="isAutoSetupActive && autoSetupStep === AutoSetupStep.Filtering">
            {{ t('pressTheseNKeysSimultaneously', highlightedModifiers.length + 1) }}
          </div>
          <div class="title" v-else>
            {{ t('layoutSetup') }}
          </div>

          <div class="actions">
            <Button v-if="selectedLayoutId" @click="continueWithLayout(selectedLayoutId)">{{ t('finishSetup') }}</Button>
          </div>
        </div>
        <div class="setup-content keyb">
          <div class="right layouts">
            <Button
              :variant="selectedLayoutId || isAutoSetupActive ? 'outlined' : 'primary'"
              :disabled="isAutoSetupActive"
              class="define-automatically-btn"
              @click="startAutoDefinition"
            >
              {{ t('defineAutomatically') }}&nbsp;<Emoji name="sparkles" />
            </Button>

            <div class="layouts-wrapper" v-if="currentSetupStep === SetupStep.Layout">
              <div class="placeholder" v-if="!sum(Object.values(layoutGroups).map((g) => g.length))">{{ t('noLayoutsAvailable') }}</div>
              <template v-else>
                <div class="layouts-section" v-show="layoutGroups.primaryLayouts.length">
                  <div class="layouts-list">
                    <div
                      class="layout-item"
                      :class="{ active: selectedLayoutId === layout.layoutId }"
                      v-for="layout in layoutGroups.primaryLayouts"
                      :key="layout.layoutId"
                      @click="setSelectedLayout(layout.layoutId, false)"
                    >
                      <div class="layout">
                        <svg class="icon">
                          <use :href="'#icon-keyboard'" />
                        </svg>
                        <span>{{ layout.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="layouts-section secondary">
                  <Button
                    class="show-secondary-layouts-btn"
                    v-show="layoutGroups.secondaryLayouts.length && !showSecondaryLayouts"
                    variant="outlined"
                    @click="showSecondaryLayouts = true"
                  >
                    {{ t('otherCompatible', [layoutGroups.secondaryLayouts.length]) }}
                  </Button>
                  <div class="layouts-list" v-show="layoutGroups.secondaryLayouts.length && showSecondaryLayouts">
                    <div
                      class="layout-item"
                      :class="{ active: selectedLayoutId === layout.layoutId }"
                      v-for="layout in layoutGroups.secondaryLayouts"
                      :key="layout.layoutId"
                      :id="`layout-${layout.layoutId}`"
                      @click="setSelectedLayout(layout.layoutId, false)"
                    >
                      <div class="layout">
                        <svg class="icon">
                          <use :href="'#icon-keyboard'" />
                        </svg>
                        <span>{{ layout.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="left">
            <div class="keyboard-wrap" v-if="autoSetupStep !== AutoSetupStep.Unsupported">
              <div v-if="selectedLayout">
                <Keyboard :layout="selectedLayout" :outlined="false" :handlePresses="true" />
                <div class="layout-definition-title">
                  <span>{{ selectedLayout.title }}</span>
                </div>
              </div>
              <div v-else-if="!isAutoSetupActive" class="keyboard-placeholder">
                <div>{{ t('keyboardPlaceholder') }}</div>
              </div>
              <Keyboard
                v-else
                @click="keyboardClickHandler"
                :layout="highlightedKey ? userLayout : currentCandidates[0] ?? userLayout"
                :keyStyleFunc="keyStyleFunc"
                :outlined="autoSetupStep !== AutoSetupStep.Defined"
                :handlePresses="true"
                v-model:layoutState="currentLayer"
                :keyToPress="highlightedKey"
                :supportOptionLayer="true"
                :showHands="autoSetupStep !== AutoSetupStep.Defined && highlightedKey ? 'layout-setup' : false"
              />
            </div>
            <div v-else class="keyboard-fail-state">
              <template v-if="currentCandidates.length">
                <div class="title" v-html="t('layoutNotCompatibleWithLanguage', [getLangTitle(allLangConfig[userLayout.languageCode].code)])"></div>
                <Button variant="secondary" @click="startAutoDefinition"> {{ t('resetPresses') }} </Button>
              </template>
              <template v-else>
                <div class="title">{{ t('layoutNotSupportedSwitch') }}</div>
                <Button variant="secondary" @click="startAutoDefinition"> {{ t('resetPresses') }} </Button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "step": {
      "language": "Language",
      "format": "Format",
      "layout": "Layout"
    },
    "layoutSetup": "What's your keyboard layout?",
    "autoSetupTitle": "Auto (recommended)",
    "manualSetupTitle": "Manual",
    "pressKeyNearShift": "Press the key immediately near your left Shift",
    "pressTheseNKeysSimultaneously": "Press the highlighted key | Press these {n} keys simultaneously",
    "yourLayout": "Your layout \"{0}\"",
    "yourLayoutNotSupportedYet": "Your layout not supported yet",
    "layoutNotCompatibleWithLanguage": "Your layout is incompatible with {0} language",
    "layoutNotSupportedSwitch": "This layout is not supported yet",
    "resetPresses": "Try again",
    "basedOnPresses": "Based on presses",
    "selectTypingLanguage": "Select typing language",
    "pressAllHighlightedKeys": "Press all highlighted keys in the same order",
    "useKeyboardToPresKeys": "Use your keyboard",
    "howEnterLooks": "What does your Enter key on your keyboard look like?",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "noLayoutsAvailable": "No layouts available",
    "keyboardPlaceholder": "Select a keyboard layout or start automatic detection",
    "finishSetup": "Finish setup",
    "defineAutomatically": "Detect automatically",
    "otherCompatible": "Other compatible ({0})"
  },
  "ru": {
    "step": {
      "language": "Язык",
      "format": "Формат",
      "layout": "Раскладка"
    },
    "layoutSetup": "Какая у вас раскладка?",
    "autoSetupTitle": "Автоматическая настройка",
    "manualSetupTitle": "Ручная настройка",
    "pressKeyNearShift": "Нажмите клавишу рядом с Shift",
    "pressTheseNKeysSimultaneously": "Нажмите эти {n} клавиш одновременно | Нажмите подсвеченную клавишу | Нажмите эти {n} клавиши одновременно",
    "yourLayout": "Ваша раскладка: {0}",
    "yourLayoutNotSupportedYet": "Ваша раскладка пока не поддерживается",
    "layoutNotCompatibleWithLanguage": "Ваша раскладка несовместима с выбранным языком ({0})",
    "layoutNotSupportedSwitch": "Эта раскладка пока не поддерживается",
    "resetPresses": "Попробовать ещё раз",
    "basedOnPresses": "Основано на ваших нажатиях",
    "selectTypingLanguage": "Выберите язык набора",
    "pressAllHighlightedKeys": "Нажмите все выделенные клавиши в том же порядке",
    "useKeyboardToPresKeys": "Используйте клавиатуру",
    "howEnterLooks": "Как выглядит клавиша Enter на вашей клавиатуре?",
    "horizontal": "Горизонтально",
    "vertical": "Вертикально",
    "noLayoutsAvailable": "Нет доступных раскладок",
    "keyboardPlaceholder": "Выберите раскладку или запустите автоматическое определение",
    "finishSetup": "Завершить настройку",
    "defineAutomatically": "Авто-определение",
    "otherCompatible": "Другие совместимые ({0})"
  },
  "es": {
    "step": {
      "language": "Idioma",
      "format": "Formato",
      "layout": "Disposición"
    },
    "layoutSetup": "¿Cuál es tu distribución de teclado?",
    "autoSetupTitle": "Automático (recomendado)",
    "manualSetupTitle": "Manual",
    "pressKeyNearShift": "Presiona la tecla justo al lado de tu Shift izquierdo",
    "pressTheseNKeysSimultaneously": "Presiona la tecla resaltada | Presiona estas {n} teclas simultáneamente",
    "yourLayout": "Tu disposición \"{0}\"",
    "yourLayoutNotSupportedYet": "Tu disposición aún no está soportada",
    "layoutNotCompatibleWithLanguage": "La distribución de su teclado no es compatible con el idioma {0}",
    "layoutNotSupportedSwitch": "Esta disposición aún no está soportada",
    "resetPresses": "Intentar de nuevo",
    "basedOnPresses": "Basado en pulsaciones",
    "selectTypingLanguage": "Selecciona el idioma de escritura",
    "pressAllHighlightedKeys": "Pulsa todas las teclas resaltadas en el mismo orden",
    "useKeyboardToPresKeys": "Usa tu teclado",
    "howEnterLooks": "¿Cómo es la tecla Intro de tu teclado?",
    "horizontal": "Horizontal",
    "vertical": "Vertical",
    "noLayoutsAvailable": "No hay disposiciones disponibles",
    "keyboardPlaceholder": "Seleccione una distribución de teclado o inicie la detección automática",
    "finishSetup": "Finalizar configuración",
    "defineAutomatically": "Detectar idioma",
    "otherCompatible": "Otros compatibles ({0})"
  },
  "de": {
    "step": {
      "language": "Sprache",
      "format": "Format",
      "layout": "Tastaturbelegung"
    },
    "layoutSetup": "Welches Tastaturlayout verwendest du?",
    "autoSetupTitle": "Automatisch (empfohlen)",
    "manualSetupTitle": "Manuell",
    "pressKeyNearShift": "Drücke die Taste direkt neben deiner linken Umschalttaste",
    "pressTheseNKeysSimultaneously": "Drücke die hervorgehobene Taste | Drücke diese {n} Tasten gleichzeitig",
    "yourLayout": "Deine Tastaturbelegung \"{0}\"",
    "yourLayoutNotSupportedYet": "Deine Tastaturbelegung wird noch nicht unterstützt",
    "layoutNotCompatibleWithLanguage": "Ihr Tastaturlayout ist mit der Sprache {0} nicht kompatibel",
    "layoutNotSupportedSwitch": "Diese Belegung wird noch nicht unterstützt",
    "resetPresses": "Erneut versuchen",
    "basedOnPresses": "Basierend auf Tastendrücken",
    "selectTypingLanguage": "Schreibsprache auswählen",
    "pressAllHighlightedKeys": "Drücke alle markierten Tasten in der gleichen Reihenfolge",
    "useKeyboardToPresKeys": "Benutze deine Tastatur",
    "howEnterLooks": "Wie sieht die Eingabetaste auf Ihrer Tastatur aus?",
    "horizontal": "Horizontal",
    "vertical": "Vertikal",
    "noLayoutsAvailable": "Keine Belegungen verfügbar",
    "keyboardPlaceholder": "Wählen Sie ein Tastaturlayout aus oder starten Sie die automatische Erkennung",
    "finishSetup": "Einrichtung abschließen",
    "defineAutomatically": "Automatisch erkennen",
    "otherCompatible": "Andere kompatible ({0})"
  },
  "fr": {
    "step": {
      "language": "Langue",
      "format": "Format",
      "layout": "Disposition"
    },
    "layoutSetup": "Quelle est votre disposition de clavier ?",
    "autoSetupTitle": "Auto (recommandé)",
    "manualSetupTitle": "Manuel",
    "pressKeyNearShift": "Appuyez sur la touche juste à côté de votre Maj gauche",
    "pressTheseNKeysSimultaneously": "Appuyez sur la touche en surbrillance | Appuyez sur ces {n} touches simultanément",
    "yourLayout": "Votre disposition \"{0}\"",
    "yourLayoutNotSupportedYet": "Votre disposition n'est pas encore prise en charge",
    "layoutNotCompatibleWithLanguage": "Votre disposition de clavier n'est pas compatible avec la langue {0}",
    "layoutNotSupportedSwitch": "Cette disposition n'est pas encore prise en charge",
    "resetPresses": "Réessayer",
    "basedOnPresses": "Basé sur les frappes",
    "selectTypingLanguage": "Sélectionnez la langue de frappe",
    "pressAllHighlightedKeys": "Appuyez sur toutes les touches surlignées dans le même ordre",
    "useKeyboardToPresKeys": "Utilisez votre clavier",
    "howEnterLooks": "À quoi ressemble la touche Entrée de votre clavier ?",
    "horizontal": "Horizontale",
    "vertical": "Verticale",
    "noLayoutsAvailable": "Aucune disposition disponible",
    "keyboardPlaceholder": "Sélectionnez une disposition de clavier ou lancez la détection automatique",
    "finishSetup": "Terminer la configuration",
    "defineAutomatically": "Détection auto",
    "otherCompatible": "Autres compatibles ({0})"
  },
  "it": {
    "step": {
      "language": "Lingua",
      "format": "Formato",
      "layout": "Layout"
    },
    "layoutSetup": "Che layout di tastiera usi?",
    "autoSetupTitle": "Auto (consigliato)",
    "manualSetupTitle": "Manuale",
    "pressKeyNearShift": "Premi il tasto immediatamente vicino al tuo Shift sinistro",
    "pressTheseNKeysSimultaneously": "Premi il tasto evidenziato | Premi questi {n} tasti contemporaneamente",
    "yourLayout": "Il tuo layout \"{0}\"",
    "yourLayoutNotSupportedYet": "Il tuo layout non è ancora supportato",
    "layoutNotCompatibleWithLanguage": "La tua configurazione della tastiera non è compatibile con la lingua {0}",
    "layoutNotSupportedSwitch": "Questo layout non è ancora supportato",
    "resetPresses": "Riprova",
    "basedOnPresses": "Basato sulle pressioni",
    "selectTypingLanguage": "Seleziona la lingua di digitazione",
    "pressAllHighlightedKeys": "Premi tutti i tasti evidenziati nello stesso ordine",
    "useKeyboardToPresKeys": "Usa la tastiera",
    "howEnterLooks": "Com'è il tasto Invio sulla tua tastiera?",
    "horizontal": "Orizzontale",
    "vertical": "Verticale",
    "noLayoutsAvailable": "Nessun layout disponibile",
    "keyboardPlaceholder": "Seleziona un layout di tastiera o avvia il rilevamento automatico",
    "finishSetup": "Completa configurazione",
    "defineAutomatically": "Rileva lingua",
    "otherCompatible": "Altri compatibili ({0})"
  }
}
</i18n>

<style lang="scss" scoped>
:global(body.setup) {
  overflow: hidden;
}

.steps {
  display: flex;
  align-items: flex-start;
  gap: var(--s-lg);
  font-size: var(--fz-md);
  font-weight: 500;
  position: absolute;
  top: var(--s-md);
  left: 50%;
  translate: -50% 0;

  .step {
    display: flex;
    align-items: center;
    gap: var(--s-sm);
    cursor: default;
    color: var(--c-text-primary);
    border-radius: var(--br-sm);
    padding: var(--s-xs) var(--s-sm);

    .indicator {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: var(--c-surface);
      border: 2px solid var(--c-secondary-border);
      font-size: var(--fz-sm);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      display: flex;
      flex-direction: column;
      .value {
        font-size: var(--fz-xs);
        color: var(--c-text-secondary);
      }
    }

    &.completed {
      align-items: flex-start;
      &:hover {
        cursor: pointer;
        background-color: var(--c-surface);
      }
      .indicator {
        background-color: var(--c-primary);
        border: 2px solid var(--c-primary);
        background-image: url('/src/assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
        color: transparent;
      }
    }

    &.active {
      .indicator {
        border: 2px solid var(--c-primary-border);
        color: var(--c-text-primary);
      }
    }
  }
}

.view-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--c-background);
  width: var(--min-viewport-inner-width);
  margin: 0 auto;
}

.setup-wrapper {
  margin-top: 8rem;
  width: var(--min-viewport-inner-width);

  .setup-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--s-sm);
    border-bottom: 1px solid var(--c-divider);
    padding-bottom: var(--s-sm);
    min-height: 45px;

    .title {
      font-size: var(--fz-lg);
      font-weight: 600;

      &.shifted {
        margin-left: 296px;
      }
    }
  }

  .setup-content {
    margin-top: var(--s-lg);

    input {
      border: 1px solid var(--c-divider);
      border-radius: var(--br-md);
      background: none;
      height: 40px;
      width: 220px;

      padding: 0 var(--s-md);
      font-size: var(--fz-sm);
      font-family: var(--ff-body);
      outline: none;
      color: var(--c-text-primary);
      &::placeholder {
        color: var(--c-text-secondary);
      }
    }
    .languages {
      margin-top: var(--s-lg);
      display: flex;
      flex-wrap: wrap;
      gap: var(--s-md);
    }
    .language {
      padding: var(--s-md) var(--s-lg);
      display: inline-block;
      border-radius: var(--br-lg);
      border: 1px solid var(--c-divider);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      color: var(--c-text-primary);
      font-size: var(--fz-lg);

      &:hover {
        background-color: var(--c-surface);
      }

      .emoji {
        position: relative;
        top: 1px;
      }
    }

    .formats-wrapper {
      width: 400px;
      margin-top: 23px;
      display: flex;
      gap: var(--s-md);

      .format-option {
        text-align: center;
        flex: 1;
        border: 1px solid var(--c-divider);
        border-radius: var(--br-md);
        padding: var(--s-lg) var(--s-md);
        cursor: pointer;

        &:hover,
        &.active {
          background-color: var(--c-surface);
        }

        svg {
          width: 100%;
          max-width: 80px;

          .enter-border {
            stroke: var(--c-text-secondary);
          }

          .enter-icon {
            fill: var(--c-text-secondary);
          }

          .secondary {
            stroke: var(--c-secondary-border);
          }
        }

        .caption {
          text-align: center;
          margin-top: 0.8em;
        }
      }
    }

    // keyboard
    &.keyb {
      width: 100%;
      height: calc(100vh - var(--header-height) - 173px);
      display: flex;
      gap: var(--s-lg);
      margin-top: 0;
      --keyb-width: 760px;
      --keyb-right-spacing: var(--s-xl);

      .title {
        font-weight: 500;
      }
      .subtitle {
        color: var(--c-text-secondary);
        font-size: var(--fz-sm);
      }

      .left {
        padding-top: var(--s-lg);

        .keyboard-wrap {
          display: block;
          // width: 960px;
          // height: 342px; // magic number to get rid of ugly ui shifts
          width: var(--keyb-width);
          position: relative;

          :deep(.keyboard-inner) {
            --c-keyboard-background: var(--c-surface);
          }

          .identification-illustration {
            width: 268px;
            position: absolute;
            bottom: calc(120px - 50px);
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
          }

          .identification-arrow {
            width: 48px;
            position: absolute;
            z-index: 2;
            left: unset;
            right: 366px;
            top: calc(78px - 50px);
          }
        }

        .layout-definition-title {
          margin-top: var(--s-sm);
          font-size: var(--fz-sm);
          color: var(--c-text-secondary);
          display: flex;
          align-items: center;
          justify-content: center;
          gap: var(--s-sm);

          .icon {
            flex: 0 0 1.25rem;
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .keyboard-placeholder {
          width: var(--keyb-width);
          height: 270px;
          background: var(--c-surface);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: var(--br-lg);
          color: var(--c-text-secondary);
        }

        .keyboard-fail-state {
          width: var(--keyb-width);
          height: 270px;
          background: var(--c-surface);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: var(--br-lg);

          .title {
            font-weight: 400;
            margin-bottom: var(--s-lg);
            text-align: center;
            // max-width: 30rem;
          }

          // .description {
          //   color: var(--c-text-secondary);
          //   text-align: center;
          //   margin-bottom: var(--s-lg);
          // }
        }
      }

      .right {
        flex: 1;
        padding-top: var(--s-lg);
        border-right: 1px solid var(--c-divider);
        padding-right: var(--s-lg);
        padding-left: 0;
        padding-bottom: var(--s-lg);
        overflow-y: auto;
        background-color: var(--c-background);
        position: relative;
        z-index: 10;

        .define-automatically-btn,
        .show-secondary-layouts-btn {
          width: 100%;
          margin-bottom: var(--s-lg);
        }

        .title {
          padding-top: var(--s-md);
          padding-bottom: 0;
          position: sticky;
          background-color: var(--c-background);
          top: 0;
          z-index: 2;
        }

        .layouts-wrapper {
          .placeholder {
            color: var(--c-text-secondary);
            font-size: var(--fz-sm);
            margin-top: var(--s-md);
          }

          .layouts-section.secondary {
            margin-top: var(--s-lg);
          }

          .layouts-list {
            display: flex;
            flex-direction: column;
            gap: var(--s-sm);
          }

          .layout-item {
            display: flex;
            align-items: center;
            border: 1px solid var(--c-divider);
            border-radius: var(--br-md);
            height: var(--footer-inner-height);
            font-size: var(--fz-sm);
            padding: 0 var(--s-md);
            gap: 0.75rem;
            cursor: pointer;

            &:hover {
              background-color: var(--c-surface);
            }

            &.active {
              background-color: var(--c-divider);
            }

            .incompatible-icon {
              display: none;
            }

            &.incompatible {
              color: var(--c-text-secondary);

              .incompatible-icon {
                display: block;
                color: var(--c-warning-text);
                margin-left: auto;
                display: inline-flex;
              }
            }

            .layout {
              display: flex;
              gap: var(--s-sm);
              align-items: center;
              overflow: hidden;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
              }

              .icon {
                flex: 0 0 1rem;
                width: 1rem;
                height: 1rem;
              }
            }

            .values {
              display: flex;
              gap: 0.75em;
              font-size: var(--fz-md);
              font-weight: 500;
              font-feature-settings:
                'tnum' on,
                'zero' on;
            }
          }
        }
      }
    }
  }
}
</style>
