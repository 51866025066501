import { allLangConfig } from '@/languages/all-lang-config'
import type { LanguageCode } from '@/languages/languages-config'
import { OS } from '@/types/main-types'
import { isBrowserEnv } from './main-utils'

export const getUserOS = () => {
  const userAgent = isBrowserEnv ? navigator.userAgent : 'Mac'
  if (userAgent.indexOf('Win') !== -1) {
    return OS.win
  }
  if (userAgent.indexOf('Mac') !== -1) {
    return OS.mac
  }
  return OS.lnx
}

export const isFirefox = () => {
  const userAgent = isBrowserEnv ? navigator.userAgent : 'Mac'
  return userAgent.indexOf('Firefox') !== -1
}

export const getUserOSTitle = (userOS?: OS) => {
  const os = userOS ?? getUserOS()
  switch (os) {
    case OS.win:
      return 'Windows'
    case OS.mac:
      return 'macOS'
    case OS.lnx:
      return 'Linux'
  }
}

export const isMacOS = () => {
  return getUserOS() === OS.mac
}

export const userSystemSwapsIntBackslashAndBackquote = isMacOS() && !isFirefox()

export const getUserLocale = () => {
  const locale = isBrowserEnv ? navigator.language : 'en'
  if (locale.includes('-')) {
    const [language, region] = locale.split('-')
    return `${language}-${region.toUpperCase()}`
  }
  return locale
}

export const getUserLanguage = (): LanguageCode => {
  const lang = getUserLocale().split('-')[0]
  if (Object.keys(allLangConfig).includes(lang)) {
    return lang as LanguageCode
  }
  return 'en'
}
