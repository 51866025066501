<script setup lang="ts">
import { formatDuration } from '@/helpers/format-utils'
import { localizedDayFormat, localizedDayjs } from '@/plugins/dayjs'
import { useCourseStore } from '@/stores/courseStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const courseStore = useCourseStore()
const todayStats = computed(() => courseStore.current.stats.today)

const metrics = computed(() => todayStats.value.metrics())
</script>

<template>
  <div class="wrapper">
    <div class="title">
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" data-name="Layer 1" viewBox="0 0 24 24" fill="currentColor">
        <path
          d="M18.5 2H18v-.5a1.5 1.5 0 1 0-3 0V2H9v-.5a1.5 1.5 0 1 0-3 0V2h-.5A5.506 5.506 0 0 0 0 7.5v11C0 21.533 2.468 24 5.5 24h13c3.032 0 5.5-2.467 5.5-5.5v-11C24 4.467 21.532 2 18.5 2Zm0 19h-13A2.503 2.503 0 0 1 3 18.5V9h18v9.5c0 1.378-1.121 2.5-2.5 2.5ZM10 12.5v2A1.5 1.5 0 0 1 8.5 16h-2A1.5 1.5 0 0 1 5 14.5v-2A1.5 1.5 0 0 1 6.5 11h2a1.5 1.5 0 0 1 1.5 1.5Z"
        />
      </svg>
      <div class="date">{{ localizedDayjs().format(localizedDayFormat()) }}</div>
      <div class="weekday">{{ localizedDayjs().format('dddd') }}</div>
    </div>

    <div class="metrics">
      <div class="metric">
        <div class="value">{{ todayStats.value.trainingCount }}</div>
        <div class="label">{{ t('lessons', todayStats.value.trainingCount) }}</div>
      </div>
      <div class="metric">
        <div class="value">{{ formatDuration(metrics.typingTimeMs / 1000) }}</div>
        <div class="label">{{ t('typingTime') }}</div>
      </div>
      <div class="metric">
        <div class="value">{{ metrics.speed.format() }}</div>
        <div class="label">{{ t('avgSpeed') }}</div>
      </div>
      <div class="metric">
        <div class="value">{{ metrics.accuracy.format() }}</div>
        <div class="label">{{ t('avgAccuracy') }}</div>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "lessons": "lesson | lessons",
    "typingTime": "typing time",
    "avgSpeed": "avg speed",
    "avgAccuracy": "avg accuracy"
  },
  "ru": {
    "lessons": "уроков | урок | урока",
    "typingTime": "время печати",
    "avgSpeed": "ср. скорость",
    "avgAccuracy": "ср. точность"
  },
  "es": {
    "lessons": "lección | lecciones",
    "typingTime": "tiempo de escritura",
    "avgSpeed": "velocidad promedio",
    "avgAccuracy": "precisión promedio"
  },
  "de": {
    "lessons": "Lektion | Lektionen",
    "typingTime": "Schreibzeit",
    "avgSpeed": "Durchsch. Geschwindigkeit",
    "avgAccuracy": "Durchsch. Genauigkeit"
  },
  "fr": {
    "lessons": "leçon | leçons",
    "typingTime": "temps de frappe",
    "avgSpeed": "vitesse moyenne",
    "avgAccuracy": "précision moyenne"
  },
  "it": {
    "lessons": "lezione | lezioni",
    "typingTime": "tempo di digitazione",
    "avgSpeed": "velocità media",
    "avgAccuracy": "precisione media"
  }
}
</i18n>

<style lang="scss" scoped>
.wrapper {
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: var(--s-md) var(--s-lg) !important;

  .title {
    margin-top: var(--s-sm);
    font-size: var(--fz-md);
    display: flex;
    gap: var(--s-sm);
    align-items: center;
    justify-content: center;
    font-weight: 500;

    svg {
      width: 14px;
      height: 14px;
    }

    .weekday {
      color: var(--c-text-tertiary);
    }
  }

  .metrics {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    gap: var(--s-xl) var(--s-md);
    margin: auto 0;
  }

  .metric {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .label {
      font-size: var(--fz-sm);
      color: var(--c-text-secondary);
      margin-bottom: 0.3rem;
    }
    .value {
      font-weight: 500;
    }
  }

  .stats-subtitle {
    // width: 200px;
    text-align: center;
    padding: 0.5rem 1.5rem 0;
    border-top: 1px solid var(--c-divider);
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);
  }
}
</style>
