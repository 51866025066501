<script setup lang="ts">
import { useAppStore } from '@/stores/appStore'
import { computed } from 'vue'

export interface Props {
  path: string // i.e. logo-beta
  ext: string // i.e. svg
  alt?: string // i.e. Logo
}
const props = defineProps<Props>()

const appStore = useAppStore()

const imgSrc = computed(() => {
  let theme = appStore.isDarkTheme ? 'dark' : 'light'
  return new URL(`/src/assets/img/${props.path}/${props.path}_${theme}.${props.ext}`, import.meta.url).href
})
</script>

<template>
  <img :src="imgSrc" draggable="false" :alt="alt" />
</template>
