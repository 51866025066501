<script setup lang="ts">
import { shortcutsConfig, type ShortcutHintConfig } from '@/configs/shortcuts-config'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import type { LocalizedText, UserLocale } from '@/plugins/i18n'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Shortcut from './Shortcut.vue'
import ShortcutButton from './ShortcutButton.vue'

const emit = defineEmits(['discard', 'show', 'hide'])

type Props = {
  shortcuts: ShortcutHintConfig[]
}
const props = defineProps<Props>()

const { t, locale } = useI18n()

const opened = defineModel<boolean>()

const toggle = () => {
  emit(opened.value ? 'hide' : 'show')
  opened.value = !opened.value
}

const shortcutsHintShortcut = new KeyboardShortcut(shortcutsConfig.shortcutsHint, () => {
  toggle()
})

const discardShortcutsHintShortcut = new KeyboardShortcut(shortcutsConfig.discard, () => {
  if (opened.value) {
    emit('discard')
  }
  opened.value = false
})

const shortcutActions = computed(() => {
  return props.shortcuts.map((shortcutConfig) => {
    return {
      shortcuts: shortcutConfig.shortcuts.map((s) => new KeyboardShortcut(s, () => {})),
      title: (shortcutConfig.title as LocalizedText)[locale.value as UserLocale],
    }
  })
})

useKeyboardShortcuts([shortcutsHintShortcut, discardShortcutsHintShortcut])
</script>

<template>
  <div class="surface" :class="{ opened }">
    <div class="backdrop" v-show="opened" @click="toggle"></div>

    <ShortcutButton :text="t('shortcuts')" :shortcut="shortcutsHintShortcut" shortcut-side="left" />

    <div class="content" v-show="opened">
      <div class="shortcuts">
        <div v-for="(action, i) in shortcutActions" :key="i" class="multi-shortcuts">
          <Shortcut v-for="(shortcut, j) in action.shortcuts" :key="shortcut.char" :shortcut="shortcut" />
        </div>
      </div>
      <div class="labels">
        <div class="label" v-for="(shortcut, i) in shortcutActions" :key="i">
          {{ shortcut.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "shortcuts": "Shortcuts"
  },
  "ru": {
    "shortcuts": "Шорткаты"
  },
  "es": {
    "shortcuts": "Atajos"
  },
  "de": {
    "shortcuts": "Tastenkürzel"
  },
  "fr": {
    "shortcuts": "Raccourcis"
  },
  "it": {
    "shortcuts": "Scorciatoie"
  }
}
</i18n>

<style lang="scss" scoped>
:global(body) {
  --c-shortcut-hint-backgrop: rgb(var(--c-backgdrop-rgb) / 0.2);
}
:global(body.dark) {
  --c-shortcut-hint-backgrop: rgb(0 0 0 / 0.5);
}

.surface {
  font-size: var(--fz-sm);
  color: var(--c-text-secondary);
  font-weight: 400;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--c-shortcut-hint-backgrop);
    z-index: 10;
  }

  &.opened {
    .content {
      position: fixed;
      left: 0;
      bottom: 0;
    }
  }

  .content {
    z-index: 11;
    display: flex;
    background-color: var(--c-modal-background);
    box-shadow: var(--box-shadow-default);
    left: 0;
    bottom: 0;
    padding: var(--s-lg);
    border-top-right-radius: var(--br-lg);
  }

  .shortcuts {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: column;
    gap: var(--s-sm);
    font-size: var(--fz-sm);
  }

  .multi-shortcuts {
    display: flex;
    gap: 0.25em;
  }

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 0.5rem;
    margin-left: 0.5rem;
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);
  }
}
</style>
