<script lang="ts" setup>
import { logout } from '@/plugins/firebase/auth'
import { getNativeLangTitle, switchAppLocale, UserLocale } from '@/plugins/i18n'
import { Theme, useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const emit = defineEmits(['login'])

const { locale, availableLocales } = useI18n({ useScope: 'global' })
const { t } = useI18n()

const userStore = useUserStore()

const dropdown = ref(null)
const isDropdownOpened = ref(false)
const route = useRoute()
const router = useRouter()

const onLocaleChange = (to: UserLocale) => {
  switchAppLocale(to)
  userStore.saveSettings({ locale: to })
  isDropdownOpened.value = false
}

onClickOutside(dropdown, (event) => (isDropdownOpened.value = false))

const onLogout = () => {
  const confirmed = confirm(t('areYouSureAboutLogout'))
  if (confirmed) {
    logout(router)
  }
}

const appStore = useAppStore()
const onThemeChange = (theme: Theme) => {
  appStore.theme = theme
}
</script>

<template>
  <div class="dropdown" ref="dropdown" :class="{ landing: route.name === 'landing' }">
    <button class="toggle" @click="isDropdownOpened = !isDropdownOpened">
      <div class="icon"></div>
      <span class="text">{{ userStore.authData?.name ?? t('more') }}</span>
      <div class="arrow" :class="{ up: isDropdownOpened }">
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.608279 0.683058C0.86398 0.438981 1.27855 0.438981 1.53425 0.683058L4.99984 3.99112L8.46542 0.683058C8.72112 0.438981 9.13569 0.438981 9.39139 0.683058C9.64709 0.927136 9.64709 1.32286 9.39139 1.56694L5.46282 5.31694C5.20712 5.56102 4.79255 5.56102 4.53685 5.31694L0.608279 1.56694C0.352579 1.32286 0.352579 0.927136 0.608279 0.683058Z"
          />
        </svg>
      </div>
    </button>
    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <template v-if="!userStore.authData">
          <li
            class="dialog-item"
            @click="
              () => {
                isDropdownOpened = false
                emit('login')
              }
            "
          >
            <div class="text primary">{{ t('login') }}</div>
          </li>

          <li class="divider"></li>
        </template>

        <!-- theme -->
        <li class="dialog-item title">{{ t('theme') }}</li>
        <li class="dialog-item" :class="{ active: appStore.theme === Theme.Dark }" @click="onThemeChange(Theme.Dark)">
          <div class="text primary">{{ t('dark') }}</div>
        </li>
        <li class="dialog-item" :class="{ active: appStore.theme === Theme.Light }" @click="onThemeChange(Theme.Light)">
          <div class="text primary">{{ t('light') }}</div>
        </li>
        <li class="dialog-item" :class="{ active: appStore.theme === Theme.Auto }" @click="onThemeChange(Theme.Auto)">
          <div class="text primary">{{ t('system') }}</div>
        </li>

        <!-- langs -->
        <li class="dialog-item title">{{ t('language') }}</li>
        <li class="dialog-item" v-for="lc in Object.values(UserLocale)" :key="lc" :class="{ active: locale === lc }" @click="onLocaleChange(lc)">
          <div class="text primary">{{ getNativeLangTitle(lc) }}</div>
        </li>

        <li class="divider"></li>

        <li class="dialog-item" @click="onLogout()">
          <div class="text primary danger">{{ userStore.authData ? t('logout') : t('resetAndExit') }}</div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "areYouSureAboutLogout": "Are you sure you want to logout?",
    "theme": "Theme",
    "dark": "Dark",
    "light": "Light",
    "system": "System",
    "logout": "Logout",
    "resetAndExit": "Exit",
    "more": "More"
  },
  "ru": {
    "areYouSureAboutLogout": "Вы уверены, что хотите выйти?",
    "theme": "Тема",
    "dark": "Тёмная",
    "light": "Светлая",
    "system": "Системная",
    "logout": "Выйти",
    "resetAndExit": "Выйти",
    "more": "Ещё"
  },
  "es": {
    "areYouSureAboutLogout": "¿Estás seguro de que quieres cerrar sesión?",
    "theme": "Tema",
    "dark": "Oscuro",
    "light": "Claro",
    "system": "Sistema",
    "logout": "Cerrar sesión",
    "resetAndExit": "Salir",
    "more": "Más"
  },
  "de": {
    "areYouSureAboutLogout": "Sind Sie sicher, dass Sie sich abmelden möchten?",
    "theme": "Thema",
    "dark": "Dunkel",
    "light": "Hell",
    "system": "System",
    "logout": "Abmelden",
    "resetAndExit": "Beenden",
    "more": "Mehr"
  },
  "fr": {
    "areYouSureAboutLogout": "Êtes-vous sûr de vouloir vous déconnecter ?",
    "theme": "Thème",
    "dark": "Sombre",
    "light": "Clair",
    "system": "Système",
    "logout": "Déconnexion",
    "resetAndExit": "Quitter",
    "more": "Plus"
  },
  "it": {
    "areYouSureAboutLogout": "Sei sicuro di voler effettuare il logout?",
    "theme": "Tema",
    "dark": "Scuro",
    "light": "Chiaro",
    "system": "Sistema",
    "logout": "Esci",
    "resetAndExit": "Uscire",
    "more": "Altro"
  }
}
</i18n>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .toggle {
    background: none;
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-sm);
    outline: none;
    display: flex;
    align-items: center;
    padding: 0 1em;
    color: var(--c-secondary-text);
    font-size: var(--fz-sm);
    font-weight: 500;
    transition: background-color 0.2s ease-out;
    // height: var(--header-element-height, 2.5rem);
    height: var(--height-btn-sm);

    &:hover {
      cursor: pointer;
      background-color: var(--c-secondary-hover);
    }

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 1.125rem;
      }
    }

    .text {
      margin-right: var(--s-sm);
    }

    .arrow {
      display: inline-block;
      transition: all 0.3s ease-out;
      position: relative;

      svg {
        width: 0.875rem;
        height: auto;

        path {
          fill: var(--c-secondary-icon);
        }
      }

      &.up {
        transform: rotate(180deg);
      }
    }
  }

  &.landing {
    .toggle {
      @media (max-width: 576px) {
        .text {
          display: none;
        }

        .icon {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-sm);
    position: absolute;
    right: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    min-width: 150px;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;

    .dialog-item {
      padding: var(--vertical-spacing) 1rem;

      .text.primary {
        color: var(--c-text-primary);
        font-size: var(--fz-sm);

        &.danger {
          color: var(--c-text-danger);
        }
      }

      &.title {
        padding: var(--vertical-spacing) 1rem var(--s-xs);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        font-weight: 500;
      }

      &:not(.title) {
        &:hover,
        &.active {
          background-color: var(--c-secondary-hover);
        }
        &:hover {
          cursor: pointer;
        }
        &.active {
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
