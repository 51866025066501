import { PunctuationMarkType, type LanguageMetadata } from '../languages-config'
import words10k from './da_10k.json'
import ngrams from './da_ngrams.json'

// prettier-ignore
// (printWidth)
export const daMetadata: LanguageMetadata = {
  code: 'da',
  title: 'Danish',
  emoji: '🇩🇰',

  lowerLetters: 'aæbcdefghijklmnoøpqrstuvwxyzå',
  upperLetters: 'AÆBCDEFGHIJKLMNOØPQRSTUVWXYZÅ',
  nonEssentialLetters: '',

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    {
      char: '—',
      type: PunctuationMarkType.Dash,
    },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.End,
    },
    {
      char: '!',
      type: PunctuationMarkType.End,
    },
    {
      char: ':',
      type: PunctuationMarkType.Comma,
    },
    {
      char: ';',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '\'',
      type: PunctuationMarkType.Bracket,
    },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
