<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue'
import Keyboard from '@/components/Keyboard.vue'
import Shortcut from '@/components/Shortcut.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { getLessonTitle } from '@/course/course-lesson-titles'
import { Chapter } from '@/course/course-types'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()

const userStore = useUserStore()
const courseStore = useCourseStore()

const lessonInfo = computed(() => userStore.currentLesson!)
const course = buildCourse(courseStore.current.layout)
const lesson = computed(() => course.content[lessonInfo.value.chapter][lessonInfo.value.index])

const onContinue = () => {
  courseStore.markInfoLessonDone(lesson.value.uniqueId)
  userStore.currentLesson = { chapter: Chapter.HomeRow, index: 1 }
}

const shortcuts = {
  continue: new KeyboardShortcut(shortcutsConfig.continue, () => {
    onContinue()
  }),
}
useKeyboardShortcuts(Object.values(shortcuts))
</script>

<template>
  <section class="trainer">
    <teleport to="#app-header">
      <AppHeader>
        <template #middle>
          <div class="training-title">
            <span>{{ getLessonTitle(lesson) }}</span>
          </div>
        </template>
        <template #right> </template>
      </AppHeader>
    </teleport>

    <div class="text-wrapper">
      <template v-if="locale === 'ru'">
        <h1>Добро пожаловать в мир слепой печати!</h1>
        <p>
          Принцип слепой печати прост: печатайте, не глядя на клавиатуру, полагаясь только на мышечную память. У каждого пальца есть свой набор
          клавиш, что делает печать быстрее и эффективнее.
        </p>
        <p>Взгляните на изображение ниже. На нём показано, <b>какие клавиши закреплены за каждым пальцем</b> и <b>исходное положение</b> рук.</p>
        <p>
          Расположите руки на клавиатуре, как показано на картинке. Под указательными пальцами вы почувствуете небольшие выпуклости — это ваши
          ориентиры! Остальные пальцы должны естественно выстроиться в ряд.
        </p>
      </template>
      <template v-else-if="locale === 'es'">
        <h1>¡Bienvenido a tu viaje de mecanografía al tacto!</h1>
        <p>
          El principio de la mecanografía al tacto es sencillo: escribe sin mirar el teclado, confiando solo en la memoria muscular. Cada dedo tiene
          su propio conjunto de teclas que manejar, lo que hace que la escritura sea más rápida y eficiente.
        </p>
        <p>Echa un vistazo a la imagen de abajo. Muestra <b>las teclas asignadas a cada dedo</b> y la <b>posición inicial</b> de tus manos.</p>
        <p>
          Coloca tus manos en el teclado como se muestra en la imagen. Notarás pequeños relieves bajo tus dedos índices: ¡estos son tus guías! El
          resto de tus dedos deberían alinearse naturalmente en una fila.
        </p>
      </template>
      <template v-else-if="locale === 'de'">
        <h1>Willkommen zu deiner Reise des Zehn-Finger-Systems!</h1>
        <p>
          Das Prinzip des Zehn-Finger-Systems ist einfach: Tippe ohne auf die Tastatur zu schauen und verlass dich nur auf dein Muskelgedächtnis.
          Jeder Finger hat seinen eigenen Satz von Tasten zu bedienen, was das Tippen schneller und effizienter macht.
        </p>
        <p>
          Wirf einen Blick auf das Bild unten. Es zeigt <b>die jedem Finger zugewiesenen Tasten</b> und die <b>Ausgangsposition</b> für deine Hände.
        </p>
        <p>
          Platziere deine Hände auf der Tastatur, wie im Bild gezeigt. Du wirst kleine Erhebungen unter deinen Zeigefingern spüren – das sind deine
          Orientierungspunkte! Die restlichen Finger sollten sich natürlich in einer Reihe anordnen.
        </p>
      </template>
      <template v-else-if="locale === 'fr'">
        <h1>Bienvenue dans votre voyage de dactylographie !</h1>
        <p>
          Le principe de la dactylographie est simple : tapez sans regarder le clavier, en vous fiant uniquement à la mémoire musculaire. Chaque doigt
          a son propre ensemble de touches à gérer, rendant la frappe plus rapide et plus efficace.
        </p>
        <p>
          Jetez un œil à l'image ci-dessous. Elle montre <b>les touches attribuées à chaque doigt</b> et la <b>position de départ</b> pour vos mains.
        </p>
        <p>
          Placez vos mains sur le clavier comme indiqué sur l'image. Vous remarquerez de petites bosses sous vos index - ce sont vos repères ! Le
          reste de vos doigts devrait naturellement s'aligner en une rangée.
        </p>
      </template>
      <template v-else-if="locale === 'it'">
        <h1>Benvenuto nel tuo percorso di scrittura alla cieca!</h1>
        <p>
          Il principio della scrittura alla cieca è semplice: digita senza guardare la tastiera, affidandoti solo alla memoria muscolare. Ogni dito ha
          il suo set di tasti da gestire, rendendo la digitazione più veloce ed efficiente.
        </p>
        <p>Dai un'occhiata all'immagine qui sotto. Mostra <b>i tasti assegnati a ciascun dito</b> e la <b>posizione iniziale</b> delle tue mani.</p>
        <p>
          Posiziona le mani sulla tastiera come mostrato nell'immagine. Noterai dei piccoli rilievi sotto i tuoi indici: questi sono i tuoi punti di
          riferimento! Il resto delle dita dovrebbe naturalmente allinearsi in fila.
        </p>
      </template>
      <template v-else>
        <h1>Welcome to your touch typing journey!</h1>
        <p>
          The principle of touch typing is simple: type without looking at the keyboard, relying only on muscle memory. Each finger has its own set of
          keys to manage, making typing faster and more efficient.
        </p>
        <p>Take a look at the image below. It shows <b>the keys assigned to each finger</b> and the <b>starting position</b> for your hands.</p>
        <p>
          Place your hands on the keyboard as shown in the image. You’ll notice small bumps under your index fingers — these are your guides! The rest
          of your fingers should naturally line up in a row.
        </p>
      </template>

      <div class="action">
        <div class="shortcut-hint">
          <i18n-t keypath="pressToStart">
            <Shortcut :shortcut="shortcuts.continue" />
          </i18n-t>
        </div>
      </div>
    </div>

    <div class="keyboard-wrapper">
      <Keyboard :handle-presses="true" :show-hands="true" :show-keyboard="true" show-finger-mapping />
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "pressToStart": "Press {0} to start typing"
  },
  "ru": {
    "pressToStart": "Нажмите {0} для начала тренировки"
  },
  "es": {
    "pressToStart": "Pulsa {0} para empezar a escribir"
  },
  "de": {
    "pressToStart": "Drücke {0}, um mit dem Tippen zu beginnen"
  },
  "fr": {
    "pressToStart": "Appuyez sur {0} pour commencer à taper"
  },
  "it": {
    "pressToStart": "Premi {0} per iniziare a digitare"
  }
}
</i18n>

<style lang="scss" scoped>
.trainer {
  flex: 1;
  display: grid;
  grid:
    '.' minmax(3rem, 1fr)
    'text' min-content
    '.' minmax(3rem, 1fr)
    'keyboard' min-content
    / 45rem;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .text-wrapper {
    max-width: 30rem;
    margin: 0 auto;
    grid-area: text;

    h1 {
      line-height: 1.4;
      margin-bottom: var(--s-lg);
    }

    h2 {
      font-size: var(--font-size-lg);
      font-weight: 600;
      margin-top: var(--s-lg);
    }

    p {
      margin-top: var(--s-md);
    }

    .action {
      margin-top: var(--s-xl);
      display: flex;
      justify-content: center;

      .shortcut-hint {
        font-size: var(--fz-sm);
      }
    }
  }

  .keyboard-wrapper {
    grid-area: keyboard;
    position: relative;
    width: 120%;
    margin-left: -10%;
    margin-bottom: -5%;
    overflow: hidden;
    padding-bottom: var(--footer-height);
  }
}
</style>
