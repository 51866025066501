<script lang="ts" setup>
import { getNativeLangTitle, switchAppLocale, UserLocale } from '@/plugins/i18n'
import { useUserStore } from '@/stores/userStore'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { locale, availableLocales } = useI18n()
const userStore = useUserStore()

const dropdown = ref(null)
const isDropdownOpened = ref(false)
const route = useRoute()

const onLocaleChange = (to: UserLocale) => {
  userStore.saveSettings({ locale: to })
  switchAppLocale(to)
  isDropdownOpened.value = false
}

onClickOutside(dropdown, (event) => (isDropdownOpened.value = false))
</script>

<template>
  <div class="dropdown" ref="dropdown" :class="{ landing: route.name === 'landing' }">
    <button class="toggle" @click="isDropdownOpened = !isDropdownOpened">
      <div class="icon"></div>
      <span class="text">{{ getNativeLangTitle(locale) }}</span>
      <div class="arrow" :class="{ up: isDropdownOpened }">
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.608279 0.683058C0.86398 0.438981 1.27855 0.438981 1.53425 0.683058L4.99984 3.99112L8.46542 0.683058C8.72112 0.438981 9.13569 0.438981 9.39139 0.683058C9.64709 0.927136 9.64709 1.32286 9.39139 1.56694L5.46282 5.31694C5.20712 5.56102 4.79255 5.56102 4.53685 5.31694L0.608279 1.56694C0.352579 1.32286 0.352579 0.927136 0.608279 0.683058Z"
          />
        </svg>
      </div>
    </button>
    <transition name="dropdown-dialog">
      <ul v-show="isDropdownOpened" class="dialog">
        <li class="dialog-item" v-for="lc in Object.values(UserLocale)" :key="lc" :class="{ active: locale === lc }" @click="onLocaleChange(lc)">
          <div class="text primary">{{ getNativeLangTitle(lc) }}</div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .toggle {
    background: none;
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-sm);
    outline: none;
    display: flex;
    align-items: center;
    padding: 0 1em;
    color: var(--c-secondary-text);
    font-size: var(--fz-sm);
    font-weight: 500;
    transition: background-color 0.2s ease-out;
    height: var(--height-btn-sm);

    &:hover {
      cursor: pointer;
      background-color: var(--c-secondary-hover);
    }

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 1.125rem;
      }
    }

    .text {
      margin-right: var(--s-sm);
    }

    .arrow {
      display: inline-block;
      transition: all 0.3s ease-out;
      position: relative;

      svg {
        width: 0.875rem;
        height: auto;

        path {
          fill: var(--c-secondary-icon);
        }
      }

      &.up {
        transform: rotate(180deg);
      }
    }
  }

  &.landing {
    .toggle {
      @media (max-width: 576px) {
        .text {
          display: none;
        }

        .icon {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .dialog {
    --vertical-spacing: 6px;
    list-style: none;
    margin: 0;
    padding: var(--vertical-spacing) 0;
    background-color: var(--c-background);
    border: 1px solid var(--c-secondary-border);
    border-radius: var(--br-sm);
    position: absolute;
    right: 0;
    top: calc(100% + 0.25rem);
    width: auto;
    min-width: 150px;
    box-shadow: var(--box-shadow-default);
    font-weight: normal;

    .dialog-item {
      padding: var(--vertical-spacing) 1rem;

      .text.primary {
        color: var(--c-text-primary);
        font-size: var(--fz-sm);

        &.danger {
          color: var(--c-text-danger);
        }
      }

      &.title {
        padding: var(--vertical-spacing) 1rem var(--s-xs);
        color: var(--c-text-tertiary);
        font-size: var(--fz-xs);
        font-weight: 500;
      }

      &:not(.title) {
        &:hover,
        &.active {
          background-color: var(--c-secondary-hover);
        }
        &:hover {
          cursor: pointer;
        }
        &.active {
          cursor: default !important;
        }
      }
    }

    .divider {
      width: 100%;
      height: var(--vertical-spacing);
    }
  }
}

.dropdown-dialog-enter-active {
  transition: all 0.1s ease-out;
}

.dropdown-dialog-leave-active {
  transition: all 0.1s ease-in;
}

.dropdown-dialog-enter-from,
.dropdown-dialog-leave-to {
  transform: translateY(-0.625rem);
  transform: scale(0.8);
  opacity: 0;
}
</style>
