// AUTH

import router from '@/router'
import { useUserStore } from '@/stores/userStore'
import { GoogleAuthProvider, getAuth, onAuthStateChanged, signInWithPopup } from 'firebase/auth'
import type { Router } from 'vue-router'

export const auth = getAuth()

export const signInWithGoogle = async () => {
  await signInWithPopup(auth, new GoogleAuthProvider())
  await router.replace({ name: 'home' })
}

export const logout = async (router: Router) => {
  const userStore = useUserStore()

  await auth.signOut()
  await router.replace({ name: 'landing' })
  userStore.reset()
}

onAuthStateChanged(auth, (user) => {
  const userStore = useUserStore()

  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    userStore.init({ email: user.email as string, name: user.displayName as string })

    if (router.currentRoute.value.name === 'landing') {
      router.push({ name: 'home' })
    }
  } else {
    userStore.init()
  }

  // // prevent flickering
  // userStore.initialized = true
})
