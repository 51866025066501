<script setup lang="ts">
import Shortcut from '@/components/Shortcut.vue'
import { shortcutsConfig, type ShortcutConfigKey } from '@/configs/shortcuts-config'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'

type Props = {
  items: string[]
  shortcutConfigKey: ShortcutConfigKey
  shortcutConfigKeyPrev?: ShortcutConfigKey
  showShortcutHint?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  showShortcutHint: true,
})

const optionIndex = defineModel<number>({ required: true })

// keyboard shortcuts

const shortcuts = {
  toggle: new KeyboardShortcut(shortcutsConfig[props.shortcutConfigKey], () => {
    optionIndex.value = (optionIndex.value + 1) % props.items.length
  }),
  prev: props.shortcutConfigKeyPrev
    ? new KeyboardShortcut(shortcutsConfig[props.shortcutConfigKeyPrev], () => {
        const newIndex = optionIndex.value - 1
        optionIndex.value = newIndex < 0 ? props.items.length - 1 : newIndex
      })
    : undefined,
}

useKeyboardShortcuts(Object.values(shortcuts).filter((s) => s !== undefined) as KeyboardShortcut[])
</script>

<template>
  <div class="options">
    <div class="shortcut" v-if="props.showShortcutHint">
      <Shortcut :shortcut="shortcuts.toggle" />
    </div>

    <div class="option" v-for="(option, index) in items" :key="option" :class="{ active: index === optionIndex }" @click="optionIndex = index">
      <div class="indicator"></div>
      <span>{{ option }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.options {
  display: flex;
  align-items: center;
  gap: var(--s-sm);
  font-size: var(--fz-sm);

  .option {
    padding: 0.5rem 0.75rem;
    border-radius: var(--br-md);
    border: 1px solid var(--c-divider);
    color: var(--c-text-secondary);
    font-size: var(--fz-xs);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1;
    font-weight: 500;

    .indicator {
      --size: 6px;
      width: var(--size);
      height: var(--size);
      background-color: var(--c-divider);
      border-radius: 100%;
    }

    &.active {
      background: var(--c-surface);
      color: var(--c-text-primary);

      .indicator {
        background-color: var(--c-primary);
      }
    }
  }
}
</style>
