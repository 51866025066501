import { installHotjar } from '@/helpers/analytics'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import { ENV } from './helpers/main-utils'
import './plugins/dayjs'
import './plugins/firebase/firebase'
import { i18n } from './plugins/i18n'
import { installTippy } from './plugins/tippy'
import router from './router'
import { getCookieSettingsFromLocalStorage } from './stores/cookieStore'

const app = createApp(App)

if (ENV.PROD) {
  Sentry.init({
    app,
    dsn: 'https://908ecf39bba6414e8cb9fb40071bdf5a@o926701.ingest.us.sentry.io/5876325',
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  })
}

app.use(createPinia())
app.use(i18n)
app.use(router)
installTippy(app)

if (getCookieSettingsFromLocalStorage().isAnalyticsAllowed) {
  installHotjar()
}

app.mount('#app')
