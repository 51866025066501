<script setup lang="ts">
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { addFeedback, type Feedback } from '@/plugins/firebase/firestore'
import { useAppStore } from '@/stores/appStore'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from './Button.vue'

const { t, locale } = useI18n()
const appStore = useAppStore()
const userStore = useUserStore()

const emit = defineEmits(['close'])

const feedback = ref<Feedback>({
  rating: 0,
  age: '',
  occupation: '',
  good: '',
  bad: '',
  videoSession: false,
  contact: userStore.authData?.email ?? '',
  currentLocale: locale.value,
})

const ageRanges = ['13-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65+']

const isLoading = ref(false)

const hideForm = () => {
  emit('close')
}

const submitForm = async () => {
  addFeedback(feedback.value)

  isLoading.value = true
  await axios.post(cloudFunctionUrl('feedback'), {
    feedback: feedback.value,
  })
  isLoading.value = false

  appStore.showToast('top', { text: t('thankYouToast'), emoji: 'raising-hands' })
  hideForm()
}

const isInvalid = computed(() => {
  return feedback.value.rating === 0 || !feedback.value.age || (feedback.value.videoSession && !feedback.value.contact)
})
</script>

<template>
  <div class="wrapper">
    <button class="close" @click="hideForm">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1.5L11 11.5M11 1.5L1 11.5" stroke="var(--c-text-secondary)" stroke-width="1.2" stroke-linecap="round" />
      </svg>
    </button>
    <div class="form">
      <div class="title">
        <svg class="icon">
          <use href="#ts-favicon"></use>
        </svg>
        <span>{{ t('title') }}</span>
      </div>

      <div class="field rating">
        <label class="required" for="form-rating">{{ t('ratingLabel') }}</label>
        <div ref="radios" class="radios">
          <label v-for="value of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :data-value="value" :class="feedback.rating == value ? 'checked' : ''">
            <input type="radio" name="rating" @input="feedback.rating = value" />
          </label>
        </div>
      </div>

      <div class="field age">
        <label class="required" for="form-age">{{ t('yourAge') }}</label>
        <div class="chips">
          <div class="chip" v-for="value of ageRanges" :class="feedback.age === value ? 'checked' : ''" @click="feedback.age = value">
            {{ value }}
          </div>
        </div>
      </div>

      <div class="field profession">
        <label for="form-profession">{{ t('occupation') }}</label>
        <input v-model="feedback.occupation" type="text" name="profession" id="form-profession" :placeholder="t('occupationPlaceholder')" />
      </div>

      <div class="field textarea">
        <label for="form-good">{{ t('goodLabel') }}</label>
        <textarea
          @keydown.stop
          rows="3"
          name="good"
          id="form-good"
          :placeholder="t('goodPlaceholder')"
          @input="(e) => (feedback.good = (<HTMLTextAreaElement>e.target).value)"
        ></textarea>
      </div>

      <div class="field textarea">
        <label for="form-bad">{{ t('badLabel') }}</label>
        <textarea
          @keydown.stop
          rows="3"
          name="bad"
          id="form-bad"
          :placeholder="t('badPlaceholder')"
          @input="(e) => (feedback.bad = (<HTMLTextAreaElement>e.target).value)"
        ></textarea>
      </div>

      <div class="field">
        <div class="title-video-session">
          {{ t('videoSessionTitle') }}
        </div>

        <div class="checkbox">
          <input
            type="checkbox"
            name="video-session"
            id="form-video-session"
            @change="(e) => (feedback.videoSession = (<HTMLInputElement>e.target).checked)"
          />
          <label for="form-video-session">{{ t('videoSessionLabel') }}</label>
        </div>

        <div v-if="feedback.videoSession" class="field contacts">
          <label for="form-contacts">{{ t('contactsLabel') }}</label>
          <input v-model="feedback.contact" type="text" name="email" id="form-contacts" :placeholder="t('contactsPlaceholder')" />
        </div>
      </div>

      <Button @click="submitForm" :loading="isLoading" :disabled="isInvalid">{{ t('submitButton') }}</Button>
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "closeButton": "Close",
    "title": "We value your opinion!",
    "ratingLabel": "How satisfied are you with Typing School?",
    "goodLabel": "What do you like the most?",
    "goodPlaceholder": "Write a message",
    "badLabel": "What can we improve?",
    "badPlaceholder": "Write a message",
    "videoSessionTitle": "Interested in a 20-minute video feedback to share your thoughts?",
    "videoSessionLabel": "Yes, I'm interested",
    "contactsLabel": "How can we contact you?",
    "contactsPlaceholder": "Enter Email",
    "submitButton": "Submit",
    "thankYouToast": "Thank you!",
    "yourAge": "Age",
    "occupation": "Occupation",
    "occupationPlaceholder": "Student, IT, etc"
  },
  "es": {
    "closeButton": "Cerrar",
    "title": "¡Tu opinión nos importa!",
    "ratingLabel": "¿Qué tan satisfecho estás con Typing School?",
    "goodLabel": "¿Qué es lo que más te gusta?",
    "goodPlaceholder": "Escribe tu mensaje",
    "badLabel": "¿Qué podemos mejorar?",
    "badPlaceholder": "Escribe tu mensaje",
    "videoSessionTitle": "¿Te interesa compartir tu opinión en una videollamada de 20 minutos?",
    "videoSessionLabel": "Sí, me interesa",
    "contactsLabel": "¿Cómo podemos contactarte?",
    "contactsPlaceholder": "Ingresa tu correo electrónico",
    "submitButton": "Enviar",
    "thankYouToast": "¡Gracias!",
    "yourAge": "Edad",
    "occupation": "Profesión",
    "occupationPlaceholder": "Estudiante, informático, etc."
  },
  "de": {
    "closeButton": "Schließen",
    "title": "Ihre Meinung ist uns wichtig!",
    "ratingLabel": "Wie zufrieden sind Sie mit Typing School?",
    "goodLabel": "Was gefällt Ihnen am besten?",
    "goodPlaceholder": "Nachricht eingeben",
    "badLabel": "Was können wir verbessern?",
    "badPlaceholder": "Nachricht eingeben",
    "videoSessionTitle": "Möchten Sie Ihre Gedanken in einem 20-minütigen Video-Feedback teilen?",
    "videoSessionLabel": "Ja, ich habe Interesse",
    "contactsLabel": "Wie können wir Sie erreichen?",
    "contactsPlaceholder": "E-Mail-Adresse eingeben",
    "submitButton": "Absenden",
    "thankYouToast": "Vielen Dank!",
    "yourAge": "Alter",
    "occupation": "Beruf",
    "occupationPlaceholder": "Student, IT-Fachkraft, usw."
  },
  "ru": {
    "closeButton": "Закрыть",
    "title": "Нам важно услышать ваше мнение!",
    "ratingLabel": "Насколько вы довольны Typing School?",
    "goodLabel": "Что вам нравится больше всего?",
    "goodPlaceholder": "Напишите сообщение",
    "badLabel": "Что мы можем улучшить?",
    "badPlaceholder": "Напишите сообщение",
    "videoSessionTitle": "Хотите поделиться своим мнением в 20-минутном видеозвонке?",
    "videoSessionLabel": "Да, мне интересно",
    "contactsLabel": "Как мы можем с вами связаться?",
    "contactsPlaceholder": "Введите email",
    "submitButton": "Отправить",
    "thankYouToast": "Спасибо!",
    "yourAge": "Возраст",
    "occupation": "Род деятельности",
    "occupationPlaceholder": "Студент, IT-специалист и т.д."
  },
  "fr": {
    "closeButton": "Fermer",
    "title": "Votre avis compte pour nous !",
    "ratingLabel": "Souhaitez-vous partager vos impressions lors d'un appel vidéo de 30 minutes ?",
    "goodLabel": "Qu'appréciez-vous le plus ?",
    "goodPlaceholder": "Écrivez votre message",
    "badLabel": "Que pouvons-nous améliorer ?",
    "badPlaceholder": "Écrivez votre message",
    "videoSessionTitle": "Souhaitez-vous partager vos impressions lors d'un appel vidéo de 30 minutes ?",
    "videoSessionLabel": "Oui, ça m'intéresse",
    "contactsLabel": "Comment pouvons-nous vous contacter ?",
    "contactsPlaceholder": "Saisissez votre e-mail",
    "submitButton": "Envoyer",
    "thankYouToast": "Merci !",
    "yourAge": "Âge",
    "occupation": "Profession",
    "occupationPlaceholder": "Étudiant, informaticien, etc."
  },
  "it": {
    "closeButton": "Chiudi",
    "title": "La tua opinione è importante per noi!",
    "ratingLabel": "Quanto sei soddisfatto di Typing School?",
    "goodLabel": "Cosa ti piace di più?",
    "goodPlaceholder": "Scrivi un messaggio",
    "badLabel": "Cosa possiamo migliorare?",
    "badPlaceholder": "Scrivi un messaggio",
    "videoSessionTitle": "Ti interessa condividere le tue opinioni in una videochiamata di 30 minuti?",
    "videoSessionLabel": "Sì, sono interessato",
    "contactsLabel": "Come possiamo contattarti?",
    "contactsPlaceholder": "Inserisci la tua email",
    "submitButton": "Invia",
    "thankYouToast": "Grazie!",
    "yourAge": "Età",
    "occupation": "Occupazione",
    "occupationPlaceholder": "Studente, informatico, ecc."
  }
}
</i18n>

<style lang="scss" scoped>
.dark .wrapper {
  background-image: url('/src/assets/img/keyboard-dark.svg');
}
.wrapper {
  position: relative;
  width: 100%;
  padding: var(--s-xl);
  border-radius: var(--br-xl);
  border: 1px solid var(--c-divider);
  background-color: var(--c-surface);
  background-image: url('/src/assets/img/keyboard-light.svg');
  background-position: right 150px;
  background-repeat: no-repeat;
  font-weight: 400;

  button.close {
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background: none;
    border: none;
    cursor: pointer;
    &:hover,
    &:focus-visible {
      path {
        stroke: var(--c-danger-text);
      }
    }
  }

  .title {
    position: relative;
    font-size: var(--fz-md);
    padding-block: 5px;
    margin-bottom: var(--s-md);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: var(--s-md);

    span {
      display: inline-block;
      margin-top: 2px;
    }

    .icon {
      width: 32px;
      height: 32px;
    }
  }

  .form {
    --max-width: 440px;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    gap: var(--s-lg);
    font-size: var(--fz-sm);

    .field {
      label {
        display: block;
        margin-bottom: var(--s-sm);
        &.required::after {
          content: ' *';
          color: var(--c-danger-text);
        }
      }
    }

    .rating,
    .contacts {
      display: flex;
      flex-direction: column;
    }

    .contacts {
      padding-left: 30px;
    }

    .textarea {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      &::after {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        bottom: 3px;
        right: 3px;
        border-bottom-right-radius: 6px;
        border-right: 1px solid var(--c-secondary-border);
        border-bottom: 1px solid var(--c-secondary-border);
        pointer-events: none;
      }
    }

    .title-video-session {
      margin-bottom: var(--s-md);
    }

    .checkbox {
      display: flex;

      input[type='checkbox'] {
        appearance: none;
        -webkit-appearance: none;

        &:focus-visible + label::before {
          border-color: var(--c-primary-border);
        }
      }

      label {
        padding-left: 28px;
        padding-block: 1px;
        position: relative;
        cursor: pointer;
        font-weight: 500;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          border-radius: var(--br-sm);
          border: 1px solid var(--c-secondary-border);
          background-color: var(--c-background);
          background-position: center;
          background-repeat: no-repeat;
          transition: 200ms;
        }
        &:hover::before {
          border-color: var(--c-secondary-border-darker);
        }
      }
      input[type='checkbox']:checked:focus-visible + label::before {
        border-color: var(--c-text-primary);
      }
      input[type='checkbox']:checked + label {
        &::before {
          border: 1px solid var(--c-primary-border);
          background-color: var(--c-primary);
          background-image: url('/src/assets/img/check.svg');
        }
        &:hover::before {
          background-color: var(--c-primary-hover);
        }
      }
    }

    .radios {
      display: flex;
      border-radius: var(--br-lg);
      border: 1px solid transparent;

      &:has(input[type='radio']:focus-visible) {
        border-color: var(--c-primary-border);
      }
      label {
        margin: unset;
        position: relative;
        width: calc(var(--max-width) / 10);
        padding: var(--s-sm) 0;
        background-color: var(--c-background);
        display: block;
        border-right: 1px solid var(--c-divider);
        border-top: 1px solid var(--c-divider);
        border-bottom: 1px solid var(--c-divider);
        cursor: pointer;
        transition: 200ms;
        &::before {
          content: attr(data-value);
          position: absolute;
          left: 50%;
          top: 50%;
          translate: -50% -50%;
        }
        &:first-child {
          border-radius: var(--br-lg) 0 0 var(--br-lg);
          border-left: 1px solid var(--c-divider);
        }
        &:last-child {
          border-radius: 0 var(--br-lg) var(--br-lg) 0;
        }
        &:hover {
          background-color: var(--c-surface);
        }
        &.checked {
          background-color: var(--c-primary);
          color: var(--c-text-on-primary);
        }
      }
      input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
      }
    }

    .chips {
      display: flex;
      flex-wrap: wrap;
      gap: var(--s-xs);

      .chip {
        border: 1px solid var(--c-divider);
        border-radius: var(--br-lg);
        padding: var(--s-sm) 0;
        flex: 1;
        text-align: center;
        background-color: var(--c-background);
        cursor: pointer;
        &:hover {
          background-color: var(--c-surface);
        }
        &.checked {
          background-color: var(--c-primary);
          color: var(--c-text-on-primary);
        }
      }
    }

    textarea,
    input[type='text'] {
      width: 100%;
      outline: none;
      border: 1px solid var(--c-secondary-border);
      border-radius: var(--br-lg);
      background-color: var(--c-background);
      padding: var(--s-md);
      color: var(--c-text-primary);
      font-size: inherit;
      font-family: inherit;
      transition: border-color 200ms;
      &::placeholder {
        opacity: 50%;
      }
      &:hover {
        border-color: var(--c-secondary-border-darker);
      }
      &:focus-visible {
        border-color: var(--c-primary-border);
      }
    }

    textarea {
      resize: vertical;
      &::-webkit-resizer {
        appearance: none;
      }
    }

    button {
      margin-top: var(--s-md);
      border-radius: var(--br-lg);
      height: 42px;
    }
    button:focus-visible {
      border-color: var(--c-text-primary);
    }
  }
}
</style>
