import type { LocalizedText } from '@/plugins/i18n'
import { TrainerView } from '../course-types'

export enum NumTrainingType {
  DigitAscDesc = 'DigitAscDesc', // 1234567890 and reverse
  DigitAscDescSpaced = 'DigitAscDescSpaced', // 1 2 3 4 5 6 7 8 9 0 and reverse
  DigitAscDescHomeRow = 'DigitAscDescHomeRow', // a12s3d4f56 j78k9l0 and reverse
  DigitAscDescHomeRowSpaced = 'DigitAscDescHomeRowSpaced', // a12 s3 d4 g56 h78 k9 l0 and reverse
  // DigitAscDescHomeRowSpaced2 = 'DigitAscDescHomeRowSpaced2', // a111222 s333 d444 f555666 h777888 k999 l000 and reverse
  PairPattern1 = 'PairPattern1', // 1212 2121 3434 4343 4545 5454 5656 6565 7878 8787 8989 9898 9090 0909 and reverse
  PairPattern2 = 'PairPattern2', // 1313 3131 2424 4242 3535 5353 4646 6464 7979 9797 8080 0808 and reverse
  PairPattern3 = 'PairPattern3', // smth for 12 then 3030 0303 4949 9494 5858 8585 6767 7676
  PairPattern4 = 'PairPattern4', // smth for 12 then 3737 7373 4848 8484 5959 9595 6060 0606
  RandomNums = 'RandomNums', // 123 456...

  RandomNumsWithWords = 'RandomNumsWithWords', // 123 abc 456...
  FibonacciSequence = 'FibonacciSequence', // 1 1 2 3 5 8 13 21...
  PowersOfTwoSequence = 'PowersOfTwoSequence', // 1 2 4 8 16 32 64 128 256 512 1024 2048 4096...
  SquareNumbersSequence = 'SquareNumbersSequence', // 1 4 9 16 25 36 49 64 81 100 121 144 169...

  LeftHand = 'LeftHand',
  RightHand = 'RightHand',
}

export type NumTrainingItem = {
  trainingType: NumTrainingType
  title: LocalizedText
  viewType: TrainerView
}

export const numTrainingItems: Record<NumTrainingType, NumTrainingItem> = {
  [NumTrainingType.DigitAscDescSpaced]: {
    trainingType: NumTrainingType.DigitAscDescSpaced,
    title: {
      en: 'First intro sequence',
      ru: 'Первый вводный урок',
      es: 'Primera secuencia introductoria',
      de: 'Erste Einführungssequenz',
      fr: `Première séquence d'introduction`,
      it: 'Prima sequenza introduttiva',
    },
    viewType: TrainerView.RunningLine,
  },
  [NumTrainingType.DigitAscDesc]: {
    trainingType: NumTrainingType.DigitAscDesc,
    title: {
      en: 'Second intro sequence',
      ru: 'Второй вводный урок',
      es: 'Segunda secuencia introductoria',
      de: 'Zweite Einführungssequenz',
      fr: `Deuxième séquence d'introduction`,
      it: 'Seconda sequenza introduttiva',
    },
    viewType: TrainerView.RunningLine,
  },
  [NumTrainingType.DigitAscDescHomeRow]: {
    trainingType: NumTrainingType.DigitAscDescHomeRow,
    title: {
      en: 'Natural finger moves (1)',
      ru: 'Естественные движения (1)',
      es: 'Movimientos naturales de los dedos (1)',
      de: 'Natürliche Fingerbewegungen (1)',
      fr: 'Mouvements naturels des doigts (1)',
      it: 'Movimenti naturali delle dita (1)',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.DigitAscDescHomeRowSpaced]: {
    trainingType: NumTrainingType.DigitAscDescHomeRowSpaced,
    title: {
      en: 'Natural finger moves (2)',
      ru: 'Естественные движения (2)',
      es: 'Movimientos naturales de los dedos (2)',
      de: 'Natürliche Fingerbewegungen (2)',
      fr: 'Mouvements naturels des doigts (2)',
      it: 'Movimenti naturali delle dita (2)',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.PairPattern1]: {
    trainingType: NumTrainingType.PairPattern1,
    title: {
      en: 'Neighbour digits',
      ru: 'Соседние цифры',
      es: 'Dígitos vecinos',
      de: 'Benachbarte Ziffern',
      fr: 'Chiffres voisins',
      it: 'Cifre adiacenti',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.PairPattern2]: {
    trainingType: NumTrainingType.PairPattern2,
    title: {
      en: 'Almost neighbours',
      ru: 'Почти соседи',
      es: 'Casi vecinos',
      de: 'Fast Nachbarn',
      fr: 'Presque voisins',
      it: 'Quasi adiacenti',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.PairPattern3]: {
    trainingType: NumTrainingType.PairPattern3,
    title: {
      en: 'Dual hand coordination (1)',
      ru: 'Координация обоих рук (1)',
      es: 'Coordinación de ambas manos (1)',
      de: 'Koordination beider Hände (1)',
      fr: 'Coordination des deux mains (1)',
      it: 'Coordinazione di entrambe le mani (1)',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.PairPattern4]: {
    trainingType: NumTrainingType.PairPattern4,
    title: {
      en: 'Dual hand coordination (2)',
      ru: 'Координация обоих рук (2)',
      es: 'Coordinación de ambas manos (2)',
      de: 'Koordination beider Hände (2)',
      fr: 'Coordination des deux mains (2)',
      it: 'Coordinazione di entrambe le mani (2)',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.RandomNums]: {
    trainingType: NumTrainingType.RandomNums,
    title: {
      en: 'Random numbers',
      ru: 'Случайные числа',
      es: 'Números aleatorios',
      de: 'Zufallszahlen',
      fr: 'Nombres aléatoires',
      it: 'Numeri casuali',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.FibonacciSequence]: {
    trainingType: NumTrainingType.FibonacciSequence,
    title: {
      en: 'Fibonacci sequence',
      ru: 'Ряд Фибоначчи',
      es: 'Secuencia de Fibonacci',
      de: 'Fibonacci-Folge',
      fr: 'Suite de Fibonacci',
      it: 'Sequenza di Fibonacci',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.PowersOfTwoSequence]: {
    trainingType: NumTrainingType.PowersOfTwoSequence,
    title: {
      en: 'Powers of 2',
      ru: 'Степени двойки',
      es: 'Potencias de 2',
      de: 'Zweierpotenzen',
      fr: 'Puissances de 2',
      it: 'Potenze di 2',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.SquareNumbersSequence]: {
    trainingType: NumTrainingType.SquareNumbersSequence,
    title: {
      en: 'Square numbers',
      ru: 'Числа в квадрате',
      es: 'Números cuadrados',
      de: 'Quadratzahlen',
      fr: 'Nombres carrés',
      it: 'Numeri quadrati',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.RandomNumsWithWords]: {
    trainingType: NumTrainingType.RandomNumsWithWords,
    title: {
      en: 'Numbers with words',
      ru: 'Числа со словами',
      es: 'Números con palabras',
      de: 'Zahlen mit Wörtern',
      fr: 'Nombres avec des mots',
      it: 'Numeri con parole',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.LeftHand]: {
    trainingType: NumTrainingType.LeftHand,
    title: {
      en: 'Left hand numbers',
      ru: 'Числа правой руки',
      es: 'Números de la mano izquierda',
      de: 'Zahlen der linken Hand',
      fr: 'Chiffres de la main gauche',
      it: 'Numeri della mano sinistra',
    },
    viewType: TrainerView.ThreeLines,
  },
  [NumTrainingType.RightHand]: {
    trainingType: NumTrainingType.RightHand,
    title: {
      en: 'Right hand numbers',
      ru: 'Числа левой руки',
      es: 'Números de la mano derecha',
      de: 'Zahlen der rechten Hand',
      fr: 'Chiffres de la main droite',
      it: 'Numeri della mano destra',
    },
    viewType: TrainerView.ThreeLines,
  },
}
