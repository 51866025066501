<script setup lang="ts">
import { logAnalyticsEvent } from '@/helpers/analytics'
import { cloudFunctionUrl } from '@/helpers/main-utils'
import { useUserStore } from '@/stores/userStore'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const finishPayment = async () => {
  const paymentId = route.params.paymentId
  if (!paymentId || !userStore.authData) {
    router.replace({ name: 'home' })
  }

  await axios.post(cloudFunctionUrl('paymentSuccess'), { email: userStore.authData!.email, paymentId })

  logAnalyticsEvent('payment_success')

  await axios
    .post(cloudFunctionUrl('notifyAboutPayment'), {
      email: userStore.authData?.email,
    })
    .then(() => {})
    .catch((error) => {})

  router.replace({ name: 'home' }).then(() => {
    window.location.reload()
  })
}

finishPayment()
</script>

<template>
  <main class="container">Updating your access...</main>
</template>

<style lang="scss" scoped>
.container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
