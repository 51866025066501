import type { LocalizedText } from '@/plugins/i18n'

export enum LessonTip {
  DoNotLookAtKeyboard = 'DoNotLookAtKeyboard',
  PatienceIsKey = 'PatienceIsKey',
  ReturnToHomeRow = 'ReturnToHomeRow',
  SlowSpeedIsNormal = 'SlowSpeedIsNormal',
  FocusOnAccuracy = 'FocusOnAccuracy',
  DailyPractice = 'DailyPractice',
  StartUsingInRealLife = 'StartUsingInRealLife',
  UseOppositePinkyForShift = 'UseOppositePinkyForShift',
}

export const tipToTextMapping: Record<LessonTip, LocalizedText> = {
  [LessonTip.DoNotLookAtKeyboard]: {
    en: "Not looking at your keyboard is super important now. If it's hard, some say you can cover it with a cloth or even a piece of paper. Get creative!",
    ru: 'Сейчас критически важно не смотреть на клавиатуру. Если это сложно, попробуйте накрыть её тканью или даже листом бумаги. Проявите фантазию!',
    es: '¡Ahora es crucial no mirar el teclado! Si te resulta difícil, algunos sugieren cubrirlo con un paño o incluso un papel. ¡Sé creativo!',
    de: 'Jetzt ist es besonders wichtig, nicht auf die Tastatur zu schauen. Falls es schwerfällt, kannst du sie mit einem Tuch oder sogar einem Blatt Papier abdecken. Sei kreativ!',
    fr: "Ne pas regarder le clavier est primordial maintenant. Si c'est difficile, certains conseillent de le couvrir avec un tissu ou même une feuille de papier. Soyez créatif !",
    it: 'Ora è fondamentale non guardare la tastiera. Se è difficile, alcuni consigliano di coprirla con un panno o persino un foglio di carta. Sii creativo!',
  },
  [LessonTip.PatienceIsKey]: {
    en: 'Typing random letters may not be the most enjoyable part. But with a little patience, you will soon be typing your first words completely blind!',
    ru: 'Печатать случайные буквы может показаться не самым увлекательным занятием. Но наберитесь терпения, и вскоре вы уже будете набирать свои первые слова вслепую!',
    es: 'Escribir letras al azar puede no ser lo más divertido. ¡Pero con un poco de paciencia, pronto estarás escribiendo tus primeras palabras completamente a ciegas!',
    de: 'Zufällige Buchstaben zu tippen mag nicht der spannendste Teil sein. Aber mit etwas Geduld wirst du bald deine ersten Wörter völlig blind schreiben!',
    fr: "Taper des lettres au hasard n'est peut-être pas la partie la plus amusante. Mais avec un peu de patience, vous taperez bientôt vos premiers mots complètement à l'aveugle !",
    it: "Digitare lettere a caso potrebbe non essere la parte più divertente. Ma con un po' di pazienza, presto scriverai le tue prime parole completamente alla cieca!",
  },
  [LessonTip.ReturnToHomeRow]: {
    en: "As you may have noticed, you're beginning to move your fingers away from the starting position. Just remember to return them back after each press!",
    ru: 'Вы наверняка заметили, что ваши пальцы начинают отходить от исходной позиции. Не забывайте возвращать их на место после каждого нажатия!',
    es: 'Habrás notado que tus dedos empiezan a alejarse de la posición inicial. ¡Recuerda devolverlos a su sitio después de cada pulsación!',
    de: 'Du hast sicher bemerkt, dass deine Finger anfangen, sich von der Ausgangsposition zu entfernen. Denk daran, sie nach jedem Anschlag zurückzubringen!',
    fr: "Vous avez sûrement remarqué que vos doigts commencent à s'éloigner de la position de départ. N'oubliez pas de les y ramener après chaque frappe !",
    it: 'Avrai notato che le tue dita iniziano ad allontanarsi dalla posizione iniziale. Ricorda di riportarle indietro dopo ogni pressione!',
  },
  [LessonTip.SlowSpeedIsNormal]: {
    en: "You are starting to learn more and more new letters, which might slow you down. Don't worry, that's completely normal!",
    ru: 'По мере изучения новых букв ваша скорость может снижаться. Не переживайте, это совершенно нормально!',
    es: 'Estás empezando a aprender más y más letras nuevas, lo que puede ralentizarte. ¡No te preocupes, es totalmente normal!',
    de: 'Du lernst immer mehr neue Buchstaben, was dich vielleicht verlangsamt. Keine Sorge, das ist völlig normal!',
    fr: "Vous commencez à apprendre de plus en plus de nouvelles lettres, ce qui peut vous ralentir. Ne vous inquiétez pas, c'est tout à fait normal !",
    it: 'Stai iniziando a imparare sempre più lettere nuove, il che potrebbe rallentarti. Non preoccuparti, è del tutto normale!',
  },
  [LessonTip.FocusOnAccuracy]: {
    en: 'Forget speed — accuracy is what matters. Hit the right keys! Bad muscle memory is hard to fix, so take your time.',
    ru: 'Забудьте о скорости — сейчас важна точность. Нажимайте правильные клавиши! Неправильную мышечную память сложно исправить, поэтому не торопитесь.',
    es: 'Olvídate de la velocidad, lo importante es la precisión. ¡Da en las teclas correctas! La mala memoria muscular es difícil de corregir, así que tómate tu tiempo.',
    de: 'Vergiss die Geschwindigkeit - Genauigkeit ist das A und O. Triff die richtigen Tasten! Schlechte Muskelgedächtnisse sind schwer zu korrigieren, also nimm dir Zeit.',
    fr: "Oubliez la vitesse, c'est la précision qui compte. Frappez les bonnes touches ! Une mauvaise mémoire musculaire est difficile à corriger, alors prenez votre temps.",
    it: 'Dimentica la velocità, è la precisione che conta. Premi i tasti giusti! Una cattiva memoria muscolare è difficile da correggere, quindi prenditi il tuo tempo.',
  },
  [LessonTip.DailyPractice]: {
    en: "The secret to mastering touch typing? Practice a little every day (10-20 minutes) and get plenty of sleep. It's like a magic potion for your muscle memory!",
    ru: 'Секрет овладения слепой печатью? Ежедневная практика (10-20 минут) и хороший сон. Это как волшебное зелье для вашей мышечной памяти!',
    es: '¿El secreto para dominar la mecanografía al tacto? Practica un poco cada día (10-20 minutos) y duerme bien. ¡Es como una poción mágica para tu memoria muscular!',
    de: 'Das Geheimnis zur Beherrschung des Zehn-Finger-Systems? Übe jeden Tag ein bisschen (10-20 Minuten) und schlafe ausreichend. Das ist wie ein Zaubertrank für dein Muskelgedächtnis!',
    fr: "Le secret pour maîtriser la dactylographie ? Pratiquez un peu chaque jour (10-20 minutes) et dormez suffisamment. C'est comme une potion magique pour votre mémoire musculaire !",
    it: "Il segreto per padroneggiare la digitazione alla cieca? Pratica un po' ogni giorno (10-20 minuti) e dormi a sufficienza. È come una pozione magica per la tua memoria muscolare!",
  },
  [LessonTip.StartUsingInRealLife]: {
    en: 'If you can, start using touch typing in your daily life. It might feel slow at first, and you may want to return to your old habits. But keep going! The effort will pay off.',
    ru: 'По возможности начните использовать слепую печать в повседневной жизни. Поначалу может быть медленно, и вы захотите вернуться к старым привычкам. Но не сдавайтесь! Ваши усилия окупятся.',
    es: 'Si puedes, empieza a usar la mecanografía al tacto en tu vida diaria. Al principio puede parecer lento y quizás quieras volver a tus viejos hábitos. ¡Pero sigue adelante! El esfuerzo valdrá la pena.',
    de: 'Wenn möglich, beginne das Zehn-Finger-System in deinem Alltag zu nutzen. Es mag sich anfangs langsam anfühlen und du könntest versucht sein, zu alten Gewohnheiten zurückzukehren. Aber bleib dran! Die Mühe wird sich lohnen.',
    fr: "Si possible, commencez à utiliser la dactylographie dans votre vie quotidienne. Ça peut sembler lent au début, et vous pourriez être tenté de revenir à vos anciennes habitudes. Mais persévérez ! L'effort en vaudra la peine.",
    it: "Se puoi, inizia a usare la digitazione alla cieca nella tua vita quotidiana. All'inizio potrebbe sembrare lento e potresti voler tornare alle vecchie abitudini. Ma continua! Lo sforzo ripagherà.",
  },
  [LessonTip.UseOppositePinkyForShift]: {
    en: "Use your pinky to press the Shift key with the opposite hand, as shown in the keyboard hint below. Once it becomes automatic, it'll make your typing even faster!",
    ru: 'Используйте мизинец противоположной руки для нажатия клавиши Shift, как показано на схеме клавиатуры ниже. Когда это войдёт в привычку, ваша печать станет ещё быстрее!',
    es: 'Usa tu meñique para presionar la tecla Shift con la mano opuesta, como se muestra en la pista del teclado abajo. ¡Una vez que se vuelva automático, tu escritura será aún más rápida!',
    de: 'Benutze deinen kleinen Finger, um die Shift-Taste mit der gegenüberliegenden Hand zu drücken, wie unten in der Tastaturanleitung gezeigt. Sobald es automatisch wird, macht es dein Tippen noch schneller!',
    fr: "Utilisez votre auriculaire pour appuyer sur la touche Maj avec la main opposée, comme indiqué dans l'indice du clavier ci-dessous. Une fois que cela devient automatique, votre frappe sera encore plus rapide !",
    it: 'Usa il mignolo per premere il tasto Shift con la mano opposta, come mostrato nel suggerimento della tastiera qui sotto. Una volta che diventa automatico, renderà la tua digitazione ancora più veloce!',
  },
}

export const tipToLessonMapping: Record<LessonTip, string> = {
  // home row
  [LessonTip.PatienceIsKey]: 'HomeRow-NewLetters-KeySKeyL',
  [LessonTip.DoNotLookAtKeyboard]: 'HomeRow-FocusOnNewLetters-KeyASemicolon',
  [LessonTip.ReturnToHomeRow]: 'HomeRow-FocusOnNewLetters-KeyGKeyH',

  // top row
  [LessonTip.SlowSpeedIsNormal]: 'TopRow-FocusOnNewLetters-KeyRKeyU',
  [LessonTip.FocusOnAccuracy]: 'TopRow-FocusOnNewLetters-KeyWKeyO',

  // bottom row
  [LessonTip.DailyPractice]: 'BottomRow-NewLetters-KeyVKeyM',

  // finger strength NO
  // bigrams NO

  // common words
  [LessonTip.StartUsingInRealLife]: 'FrequentWords-PopularLowerWords-0',

  // shift
  [LessonTip.UseOppositePinkyForShift]: 'Shift-ShiftLettersRow-Home',

  // punctuation NO
  // full review NO
  // numbers NO
}
