import { i18n, localizedSpeedUnit } from '@/plugins/i18n'

export enum Metric {
  Speed = 'Speed',
  Accuracy = 'Accuracy',
  Presses = 'Presses',
}

export class Speed {
  constructor(public value?: number) {} // CPM

  public get cpm(): number {
    return this.value ?? 0
  }

  public get wpm(): number {
    return this.cpm / 5
  }

  public format({
    unit = undefined,
    precision = undefined,
    placeholder = undefined,
    appendUnit = true,
  }: {
    unit?: 'wpm' | 'cpm'
    precision?: number
    placeholder?: string
    appendUnit?: boolean
  } = {}) {
    const resultUnit = unit ?? localizedSpeedUnit()
    const unitTitle = i18n.global.t(resultUnit)
    precision = precision ?? (resultUnit === 'wpm' ? 1 : 0)

    if (this.value === undefined) {
      return placeholder ?? '—' + (appendUnit ? ` ${unitTitle}` : '')
    }

    const value = (resultUnit === 'wpm' ? this.wpm : this.cpm).toFixed(precision)
    return appendUnit ? `${value} ${unitTitle}` : value
  }
}

export class Accuracy {
  public value?: number
  constructor(value?: number, variant: 'normalized' | 'absolute' = 'normalized') {
    this.value = variant === 'normalized' ? value : value && value / 100
  }

  public get normalized(): number {
    return this.value ?? 0
  }

  public get percentage(): number {
    return this.normalized * 100
  }

  public format({
    precision = 1,
    placeholder = undefined,
    appendUnit = true,
  }: { precision?: number; placeholder?: string; appendUnit?: boolean } = {}) {
    if (this.value === undefined) {
      return placeholder ?? '—' + (appendUnit ? ' %' : '')
    }

    const value = this.percentage!.toFixed(precision)
    return appendUnit ? `${value}%` : value
  }
}
