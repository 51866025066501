import { useAppStore } from '@/stores/appStore'
import { DigitsData } from '@/types/DigitsData'
import { getContrastTextColor, hsl, hslToString, type HSL } from '@/types/color-types'
import type { Accuracy, Speed } from '@/types/metric-types'
import { max, min } from 'lodash-es'
import type { StyleValue } from 'vue'

export type KeyData = {
  presses: number
  accuracy: Accuracy
  speed: Speed
}

// FIXME: sync with accuracyColor()
export const ACCURACY_COLOR_THRESHOLDS = [
  { value: 92, color: '#EC6B5F' },
  { value: 95, color: '#F5C152' },
  { value: 98, color: '#5FC752' },
]
// export const ACCURACY_COLOR_THRESHOLDS = [
//   { value: 92, color: '#EC6B5F' },
//   { value: 95, color: '#F5C152' },
//   { value: 98, color: '#5FC752' },
// ]

// Accuracy color scale (value from 0 to 100)
export const accuracyColor = (value: number, min?: number, max?: number): HSL => {
  const minVal = min ?? ACCURACY_COLOR_THRESHOLDS[0].value
  const middleVal = min && max ? (min + max) / 2 : ACCURACY_COLOR_THRESHOLDS[1].value
  const maxVal = max ?? ACCURACY_COLOR_THRESHOLDS[2].value

  const opacity = 100

  const red = hsl(5, 79, 65, opacity)
  const orange = hsl(41, 89, 64, opacity)
  const green = hsl(113, 51, 55, opacity)

  if (value >= maxVal) {
    return green
  } else if (value <= minVal) {
    return red
  }

  // Interpolate between Orange and Red/Green
  if (value < middleVal) {
    // Scale between Red and Orange
    const scale = (value - minVal) / (middleVal - minVal)
    return hsl(
      red.hue + (orange.hue - red.hue) * scale,
      red.saturation + (orange.saturation - red.saturation) * scale,
      red.lightness + (orange.lightness - red.lightness) * scale,
      opacity,
    )
  } else {
    // Scale between Orange and Green
    const scale = (value - middleVal) / (maxVal - middleVal)
    return hsl(
      orange.hue + (green.hue - orange.hue) * scale,
      orange.saturation + (green.saturation - orange.saturation) * scale,
      orange.lightness + (green.lightness - orange.lightness) * scale,
      opacity,
    )
  }
}

const calcOpacity = (value: number, minVal: number, maxVal: number, startFrom = 10) => {
  const scale = minVal === maxVal ? 1 : (value - minVal) / (maxVal - minVal)
  const opacity = Math.round(startFrom + 80 * scale)
  return opacity
}

// Speed color scale (wpm, min/max)
export const speedColor = (value: number, minVal: number, maxVal: number): string => {
  const appStore = useAppStore()
  const isDarkTheme = appStore.isDarkTheme

  const baseColor = ['0 117 235', '0 117 235']
  const opacity = calcOpacity(value, minVal, maxVal)
  return `rgb(${baseColor[isDarkTheme ? 1 : 0]} / ${opacity}%)`
}

// Press count color scale (min/max)
export const pressesColor = (value: number, minVal: number, maxVal: number): string => {
  const appStore = useAppStore()
  const isDarkTheme = appStore.isDarkTheme

  const baseColor = ['132 163 190', '103 142 176']
  // const baseColor = ['255 134 20', '255 134 20']

  // pass 40 to make it better looking for keyb coverage stats
  const opacity = calcOpacity(value, minVal, maxVal, 25)
  return `rgb(${baseColor[isDarkTheme ? 1 : 0]} / ${opacity}%)`
}

// css

export const coloredMetricsCSS = (keysRawValue: KeyData[]) => {
  type StylesObject = { speed: StyleValue; accuracy: StyleValue; presses: StyleValue }
  const result: DigitsData<StylesObject> = DigitsData.init({ speed: {}, accuracy: {}, presses: {} })

  const minPresses = min(keysRawValue.filter((k) => k.presses !== 0).map((k) => k.presses)) as number
  const maxPresses = max(keysRawValue.filter((k) => k.presses !== 0).map((k) => k.presses)) as number

  const minSpeed = min(keysRawValue.filter((k) => k.speed.value !== undefined).map((k) => k.speed.cpm)) as number
  const maxSpeed = max(keysRawValue.filter((k) => k.speed.value !== undefined).map((k) => k.speed.cpm)) as number

  for (let i = 0; i < keysRawValue.length; i++) {
    const item = keysRawValue[i]

    if (!item) {
      continue
    }

    const keyData = result.rawValue[i]
    if (item.presses !== 0) {
      const pressesBg = pressesColor(item.presses, minPresses, maxPresses)
      keyData.presses = {
        backgroundColor: pressesBg,
        color: 'var(--c-text-primary)',
        border: 'none',
      }
    }
    if (item.accuracy.value !== undefined) {
      const accuracyBg = accuracyColor(item.accuracy.percentage)
      keyData.accuracy = {
        backgroundColor: hslToString(accuracyBg),
        color: hslToString(getContrastTextColor(accuracyBg)),
        border: 'none',
      }
    }
    if (item.speed.value !== undefined) {
      const speedBg = speedColor(item.speed.cpm, minSpeed, maxSpeed)
      keyData.speed = {
        backgroundColor: speedBg,
        color: 'var(--c-text-primary)',
        border: 'none',
      }
    }
  }
  return result
}
