<script setup lang="ts">
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import DarkLightImage from '@/components/DarkLightImage.vue'
import Keyboard from '@/components/Keyboard.vue'
import Shortcut from '@/components/Shortcut.vue'
import ShortcutButton from '@/components/ShortcutButton.vue'
import ShortcutHint from '@/components/ShortcutHint.vue'
import TipModal from '@/components/TipModal.vue'
import { shortcutsConfig, shortcutsHintConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { generateLesson } from '@/course/course-lesson-texts'
import { getLessonTitle } from '@/course/course-lesson-titles'
import { LessonTip, tipToLessonMapping } from '@/course/course-tips'
import { Chapter } from '@/course/course-types'
import { logAnalyticsEvent } from '@/helpers/analytics'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import type { KeyPress } from '@/helpers/press-helper'
import { LimitType, ROW_LENGTH, Trainer, TrainingPhase, type FullTypingResult } from '@/helpers/Trainer'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingStore } from '@/stores/trainingStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import { computed, onBeforeMount, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

onBeforeMount(() => {
  document.body.classList.add('typing')
  document.documentElement.scrollTo(0, 0)
})
onUnmounted(() => {
  document.body.classList.remove('typing')
})

const { t } = useI18n()
const router = useRouter()
const userStore = useUserStore()
const trainingStore = useTrainingStore()
const courseStore = useCourseStore()
const lessonInfo = userStore.currentLesson!

const trainer = ref<Trainer>(new Trainer(courseStore.current.layout))
const course = buildCourse(courseStore.current.layout)
const lesson = course.content[lessonInfo.chapter][lessonInfo.index]

// text.value = 'übc'
let text = ref(generateLesson(course, lessonInfo.chapter, lessonInfo.index))

const loading = ref(false)
const finished = ref(false)

// TODO
// const trainingDuration = 1 * 5 * 1000
// const trainingDuration = userStore.settings.trainingDurationMs

trainer.value.init({
  text: text.value,
  limitType: LimitType.Unlimited,
  limit: 0,
  lessonCoords: lessonInfo,
  onFinish: (result: FullTypingResult) => {
    finished.value = true
    const typingResult = CharTypingResult.fromFullTypingResult(result, courseStore.current.layout.os)
    trainingStore.lastTypingResult = result

    courseStore.saveTraining(result, typingResult)

    // analytics
    if (lessonInfo.chapter === Chapter.HomeRow && lessonInfo.index === 1) {
      logAnalyticsEvent('first_training_complete')
    } else if (
      !userStore.hasFullAccess &&
      lessonInfo.chapter === Chapter.HomeRow &&
      lessonInfo.index === course.content[lessonInfo.chapter].length - 1
    ) {
      logAnalyticsEvent('last_training_complete')
    }

    setTimeout(() => {
      // router.push({ name: 'home' })
      router.push({ name: 'result' })
    }, 200)
  },
})

// PRO TIPS
const lessonTip = computed(() => Object.entries(tipToLessonMapping).find(([k, v]) => v === lesson.uniqueId)?.[0] as LessonTip | undefined)
const showTip = ref(!!lessonTip.value)

if (showTip.value) {
  trainer.value.pause()
} else {
  trainer.value.start()
}

const onTipDismiss = () => {
  showTip.value = false
  trainer.value.start()
}

// Shortcuts

const showShortcutHint = ref(false)

const onShortcutHintDiscard = () => {
  // do nothing, pause shortcut will handle it
}

const onShortcutHintShow = () => {
  trainer.value.pause()
}

const onShortcutHintHide = () => {
  trainer.value.resume()
}

const executeIfPaused = (func: Function, closeShortcutHint = true) => {
  if (showTip.value) {
    return
  }

  if (trainer.value.phase === TrainingPhase.Paused) {
    func()
    if (closeShortcutHint) {
      showShortcutHint.value = false
    }
  }
}

const shortcuts = {
  dismissTip: new KeyboardShortcut(shortcutsConfig.continue, () => {
    if (showTip.value) {
      onTipDismiss()
    }
  }),

  pause: new KeyboardShortcut(shortcutsConfig.discard, () => {
    if (trainer.value.phase !== TrainingPhase.Paused) {
      trainer.value.pause()
      if (!showShortcutHint.value) {
        showShortcutHint.value = true
      }
    } else {
      executeIfPaused(() => trainer.value.resume())
    }
  }),

  resume: new KeyboardShortcut(shortcutsConfig.exit, () => {
    executeIfPaused(() => router.push({ name: 'home' }))
  }),

  restart: new KeyboardShortcut(shortcutsConfig.restart, () => {
    executeIfPaused(() => trainer.value.restart(generateLesson(course, lessonInfo.chapter, lessonInfo.index)))
  }),

  toggleHands: new KeyboardShortcut(shortcutsConfig.toggleHands, () => {
    executeIfPaused(() => userStore.saveSettings({ isFingerHintShown: !userStore.settings.isFingerHintShown }), false)
  }),

  toggleKeyboard: new KeyboardShortcut(shortcutsConfig.toggleKeyboard, () => {
    executeIfPaused(() => userStore.saveSettings({ isKeyboardHintShown: !userStore.settings.isKeyboardHintShown }), false)
  }),

  toggleFingerMapping: new KeyboardShortcut(shortcutsConfig.toggleFingerMapping, () => {
    // TODO toggle finger mapping
    // executeIfPaused(() => (userStore.settings.fingerMapping = userStore.settings.fingerMapping === 'optimized' ? 'logical' : 'optimized'), false)
  }),
}

useKeyboardShortcuts(Object.values(shortcuts))

// Misc

onUnmounted(() => {
  trainer.value.clearInterval()
})

const firstWrongIndex = ref(-1)

// Handle press events from Keyboard
const press = (keyPress: KeyPress) => {
  trainer.value.registerPress(keyPress)
  firstWrongIndex.value = trainer.value.firstWrongIndex()
}

const progress = computed(() => {
  if (!trainer.value || trainer.value.phase === TrainingPhase.Finished) {
    return ''
  }

  // if (training.limitType === LimitType.Duration) {
  // return formatDuration(trainingDuration / 1000 - trainer.value.elapsedTime('s'))
  // } else {
  const totalChars = trainer.value.textLength()
  const cursor = trainer.value!.correctCursorIndex
  const progress = totalChars ? (cursor / totalChars) * 100 : 0
  return `${progress.toFixed(1)}%`
  // }
})
</script>

<template>
  <section class="trainer" :class="{ paused: trainer.isPaused() }">
    <teleport to="#app-header">
      <AppHeader class="smooth-opacity-transition" :style="{ opacity: finished ? 0 : 1 }">
        <template #middle>
          <div class="training-title">
            <span>{{ getLessonTitle(lesson) }}</span>
          </div>
        </template>
        <template #right>
          <div class="top-right-info">
            <template v-if="!trainer.isPaused()">
              <ShortcutButton :text="t('pause')" :shortcut="shortcuts.pause" />
              <div class="divider"></div>
            </template>
            <span class="time-to-finish">{{ progress }}</span>
          </div>
        </template>
      </AppHeader>
    </teleport>

    <div class="text-wrapper smooth-opacity-transition" :style="{ opacity: finished ? 0 : 1 }">
      <div v-for="(row, i) in trainer.getTextState(3)" :key="i" class="row">
        <div
          v-for="(charState, j) in row"
          :key="charState.index"
          class="cell"
          :class="{
            correct: charState.typed && charState.typed.isEqual(charState.toType),
            wrong: charState.typed && firstWrongIndex !== -1 && firstWrongIndex <= charState.index,
            cursor: trainer.cursorIndex === charState.index && !trainer.isPaused(),
            'last-typed': trainer.cursorIndex - 1 === charState.index,
            'last-in-row': row.length - 1 === j,
            'typed-dead': charState.typedDead,
          }"
          :style="{ flex: `0 0 ${100 / ROW_LENGTH}%` }"
        >
          <div class="inner">
            {{ charState.typed?.value || charState.toType.value }}
          </div>
        </div>
      </div>

      <div v-show="trainer.isPaused()" class="pause-view">
        <div v-if="!showTip" class="title">
          <i18n-t keypath="pressToResume">
            <Shortcut :shortcut="shortcuts.pause" />
          </i18n-t>
        </div>
      </div>
    </div>
    <TipModal v-if="showTip && lessonTip" :shortcut="shortcuts.dismissTip" :tip="lessonTip" @dismiss="onTipDismiss" />

    <div class="keyboard-wrapper smooth-opacity-transition" :style="{ opacity: finished || showTip ? 0 : 1 }">
      <Keyboard
        :keyToPress="trainer.keyToPress()"
        :handle-presses="!trainer.isPaused()"
        :show-hands="userStore.settings.isFingerHintShown"
        :show-keyboard="userStore.settings.isKeyboardHintShown"
        @press="press"
        show-finger-mapping
      />
    </div>

    <div v-show="!trainer || loading" class="loading-placeholder trainer-size-view">
      <DarkLightImage path="spinner" ext="svg" :alt="t('loading3Dot')" />
    </div>

    <teleport to="#app-footer">
      <AppFooter>
        <template #left>
          <ShortcutHint
            :shortcuts="shortcutsHintConfig.trainer()"
            v-model="showShortcutHint"
            @discard="onShortcutHintDiscard"
            @show="onShortcutHintShow"
            @hide="onShortcutHintHide"
          />
        </template>
        <template #middle>
          <div></div>
        </template>
        <template #right>
          <div></div>
        </template>
      </AppFooter>
    </teleport>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "pause": "Pause",
    "pressToResume": "Press {0} to resume"
  },
  "ru": {
    "pause": "Пауза",
    "pressToResume": "Нажмите {0} чтобы продолжить"
  },
  "es": {
    "pause": "Pausa",
    "pressToResume": "Pulsa {0} para reanudar"
  },
  "de": {
    "pause": "Pause",
    "pressToResume": "Drücke {0} zum Fortsetzen"
  },
  "fr": {
    "pause": "Pause",
    "pressToResume": "Appuyez sur {0} pour reprendre"
  },
  "it": {
    "pause": "Pausa",
    "pressToResume": "Premi {0} per riprendere"
  }
}
</i18n>

<style lang="scss" scoped>
:global(body.typing) {
  overflow: hidden;
}

.training-title {
  font-weight: 500;
  height: var(--header-inner-height);
  font-size: var(--fz-sm);
  display: grid;
  place-items: center;
}

.top-right-info {
  display: flex;
  align-items: center;
  gap: var(--grid-cell);

  .time-to-finish {
    text-align: center;
    min-width: 52px;
    font-size: var(--fz-sm);
    font-weight: 500;
    font-feature-settings: 'tnum';
  }
  .divider {
    height: 1rem;
    width: 1px;
    background-color: var(--c-divider);
  }
}

.trainer {
  flex: 1;
  display: grid;
  grid:
    '.' minmax(3rem, 1fr)
    'text' min-content
    '.' minmax(3rem, 1fr)
    'keyboard' min-content
    / 45rem;
  justify-content: center;
  position: relative;

  .trainer-size-view {
    display: grid;
    place-content: center center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }

  .loading-placeholder {
    background-color: var(--c-background);
    :deep(img) {
      width: 80px;
      height: 80px;
    }
  }

  .text-wrapper {
    grid-area: text;
    display: flex;
    flex-direction: column;
    background-color: var(--c-background);
    font-size: 1.125rem;
    position: relative;

    .row {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      // border-bottom: 1px solid var(--c-secondary-border);

      // &:first-child {
      // border-top: 1px solid var(--c-secondary-border);
      // }

      .cell {
        $border-radius: var(--br-xs);

        display: flex;
        flex-direction: column;
        padding: 0.4em 0;

        .inner {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 1em;
          font-family: var(--ff-mono);
        }

        &.correct {
          .inner {
            background-color: var(--c-success-bg);
            color: var(--c-success-text);
          }
        }

        &.wrong {
          .inner {
            background-color: var(--c-danger-bg);
            color: var(--c-danger-text);
          }
        }

        &:first-child {
          .inner {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
          }
        }

        &.last-in-row {
          .inner {
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
        }

        &.typed-dead {
          .inner {
            color: var(--c-primary);
            background: none;
          }
        }

        &.cursor {
          position: relative;

          .inner {
            background-color: var(--c-surface);
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }

          &:after {
            content: ' ';
            display: block;
            position: absolute;
            top: calc(100% - 4px);
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: $border-radius;
            background: var(--c-primary);
          }
        }
      }
    }

    .pause-view {
      background: rgb(var(--c-background-rgb) / 0.5);
      backdrop-filter: blur(8px);
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 120%;
      margin-left: -10%;
      height: 110%;
      top: -5%;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      .title {
        font-size: var(--fz-lg);
        font-weight: 400;
        color: var(--c-text-primary);
        display: flex;
        gap: 0.5rem;
        align-items: center;

        :deep(.shortcut) {
          font-size: var(--fz-md);
        }
      }
    }
  }

  &.paused {
    .text-wrapper {
      .pause-view {
        display: flex;
      }
    }
  }

  .keyboard-wrapper {
    grid-area: keyboard;
    position: relative;
    width: 120%;
    margin-left: -10%;
    margin-bottom: -5%;
  }
}

.smooth-opacity-transition {
  transition: opacity 0.3s ease-in-out;
}

.padding-placeholder {
  height: var(--header-height);
}
</style>
