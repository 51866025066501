<script setup lang="ts">
import Button from '@/components/Button.vue'
import DarkLightImage from '@/components/DarkLightImage.vue'
import Emoji from '@/components/Emoji.vue'
import Shortcut from '@/components/Shortcut.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { logAnalyticsEvent } from '@/helpers/analytics'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { resolveToSupportedLocale, switchAppLocale } from '@/plugins/i18n'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

const { t, locale } = useI18n()
const router = useRouter()
const route = useRoute()

const userStore = useUserStore()
const courseStore = useCourseStore()
const statsExists = computed(() => courseStore.currentExists)

const nextViewName = computed(() => (statsExists.value ? 'home' : 'courseSetup'))
const buttonLabel = computed(() => (statsExists.value ? 'continue' : 'tryFreeLessons'))

// resolve locale
const userLocale = route.params.locale ? resolveToSupportedLocale(route.params.locale as string) : userStore.settings.locale
switchAppLocale(userLocale)
userStore.saveSettings({ locale: userLocale })

watchEffect(() => {
  window.document.title = t('pageTitle')
})

const goNext = () => {
  if (!statsExists.value) {
    logAnalyticsEvent('click_get_started')
  }
  router.push({ name: nextViewName.value })
}

const goNextShortcut = new KeyboardShortcut(shortcutsConfig.continue, goNext)

useKeyboardShortcuts([goNextShortcut])
</script>

<template>
  <section class="home">
    <div class="main">
      <h1 class="title">{{ t('title') }}</h1>
      <div class="description">
        <p>{{ t('body1') }}&nbsp;<Emoji name="fire" /></p>
        <p>
          {{ t('body2') }}
        </p>
      </div>

      <div class="actions">
        <Button size="lg" type="primary" @click="goNext">
          {{ t(buttonLabel) }}
        </Button>

        <div class="shortcut-hint" v-if="!statsExists">
          {{ t('noRegistrationNeeded') }}
          <!-- <Shortcut :shortcut="goNextShortcut" /> -->
        </div>
        <div class="shortcut-hint" v-else="statsExists">
          {{ t('orPress') }}
          <Shortcut :shortcut="goNextShortcut" />
        </div>
      </div>
    </div>
    <div class="illustration">
      <DarkLightImage path="keyboard" ext="png" alt="Keyboard" />
    </div>
  </section>
</template>

<i18n lang="json">
{
  "en": {
    "pageTitle": "Touch Typing Program for Beginners — Typing School",
    "title": "Touch typing, reimagined.",
    "body1": "Learn 10-finger typing skill and unlock a new, hacker-level of productivity",
    "body2": "A typing course carefully designed for beginners. It supports 16 languages and tailored to your specific layout.",
    "tryFreeLessons": "Try for free",
    "noRegistrationNeeded": "No registration needed"
  },
  "ru": {
    "pageTitle": "Тренажёр слепой печати с нуля — Typing School",
    "title": "Слепая печать, в новом формате.",
    "body1": "Освойте скоростной набор текста и откройте для себя новый уровень продуктивности",
    "body2": "Курс печати, созданный специально для начинающих. Поддерживает 16 языков и индивидуально подстраивается под вашу раскладку клавиатуры.",
    "tryFreeLessons": "Попробовать бесплатно",
    "noRegistrationNeeded": "Без регистрации"
  },
  "es": {
    "pageTitle": "Aprende a escribir con todos los dedos desde cero — Typing School",
    "title": "Mecanografía al tacto, reinventada.",
    "body1": "Aprende a escribir con 10 dedos y desbloquea un nuevo nivel de productividad digno de un hacker",
    "body2": "Un curso de mecanografía cuidadosamente diseñado para principiantes. Compatible con 16 idiomas y adaptado a tu disposición de teclado específica.",
    "tryFreeLessons": "Prueba gratis",
    "noRegistrationNeeded": "No se requiere registro"
  },
  "de": {
    "pageTitle": "Zehnfingersystem von Grund auf lernen — Typing School",
    "title": "Zehnfingersystem, neu gedacht.",
    "body1": "Erlerne das 10-Finger-Tippen und erschließe ein neues Produktivitätslevel wie ein Hacker",
    "body2": "Ein sorgfältig für Anfänger entwickelter Tippkurs. Er unterstützt 16 Sprachen und ist auf dein spezielles Tastaturlayout zugeschnitten.",
    "tryFreeLessons": "Kostenlos testen",
    "noRegistrationNeeded": "Keine Anmeldung erforderlich"
  },
  "fr": {
    "pageTitle": "Apprenez la dactylographie à dix doigts dès le début — Typing School",
    "title": "La dactylographie réinventée.",
    "body1": "Apprenez la frappe à 10 doigts et débloquez un nouveau niveau de productivité digne d'un hacker",
    "body2": "Un cours de frappe soigneusement conçu pour les débutants. Il prend en charge 16 langues et s'adapte à votre disposition de clavier spécifique.",
    "tryFreeLessons": "Essayer gratuitement",
    "noRegistrationNeeded": "Pas d'inscription nécessaire"
  },
  "it": {
    "pageTitle": "Impara a digitare con tutte le dita da zero — Typing School",
    "title": "Dattilografia, reinventata.",
    "body1": "Impara a scrivere con 10 dita e sblocca un nuovo livello di produttività da hacker",
    "body2": "Un corso di dattilografia attentamente progettato per principianti. Supporta 16 lingue ed è personalizzato per il tuo specifico layout di tastiera.",
    "tryFreeLessons": "Prova gratuitamente",
    "noRegistrationNeeded": "Nessuna registrazione richiesta"
  }
}
</i18n>

<style lang="scss" scoped>
section.home {
  flex: 1;
  display: grid;
  grid:
    'main' auto
    'illustration' min-content
    / 100%;
  justify-items: center;
  text-align: center;
}
// Main block

.main {
  grid-area: main;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;

  .title {
    margin: 0;
    font-size: var(--fz-h1);
    margin-bottom: var(--s-lg);
  }

  .description {
    max-width: 38em;
    margin: 0 auto;

    p {
      margin-bottom: var(--s-md);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .actions {
    margin-top: var(--s-xxl);
  }

  .shortcut-hint {
    font-size: var(--fz-sm);
    color: var(--c-text-secondary);
    margin-top: var(--s-md);
  }
}

// Illustration

.illustration {
  max-width: 50rem;
  height: calc(50rem / 4.71);
  grid-area: illustration;
  font-size: 0;
  position: relative;
  top: var(--footer-height);
  overflow: hidden;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgb(var(--c-background-rgb)) 5%, rgb(var(--c-background-rgb) / 0) 100%);
  }
}
</style>
