import { PunctuationMarkType, type LanguageMetadata } from '../languages-config'
import words10k from './fr_10k.json'
import ngrams from './fr_ngrams.json'

// prettier-ignore
// (printWidth)
export const frMetadata: LanguageMetadata = {
  code: 'fr',
  title: 'French',
  emoji: '🇫🇷',

  lowerLetters: 'abcdefghijklmnopqrstuvwxyzàâæçéèêëîïôœùûüÿ',
  upperLetters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZÀÂÆÇÉÈÊËÎÏÔŒÙÛÜŸ',
  nonEssentialLetters: 'ÿŸæÆœŒÇÉ',

  punctuation: [
    {
      char: '-',
      type: PunctuationMarkType.Hyphen,
    },
    {
      char: '―',
      type: PunctuationMarkType.Dash,
    },
    {
      char: ',',
      type: PunctuationMarkType.Comma,
    },
    {
      char: '.',
      type: PunctuationMarkType.End,
    },
    {
      char: '?',
      type: PunctuationMarkType.EndSpaced,
    },
    {
      char: '!',
      type: PunctuationMarkType.EndSpaced,
    },
    {
      char: ':',
      type: PunctuationMarkType.CommaSpaced,
    },
    {
      char: ';',
      type: PunctuationMarkType.CommaSpaced,
    },
    {
      char: '()',
      type: PunctuationMarkType.Bracket,
    },
    {
      char: '"',
      type: PunctuationMarkType.BracketSpaced,
    },
    {
      char: '«»',
      type: PunctuationMarkType.BracketSpaced,
    },
  ],
  
  dicts: {
    words10k,
    ngrams,
  }
}
