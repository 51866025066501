import dayjs from 'dayjs'
import { createI18n } from 'vue-i18n'

export enum UserLocale {
  en = 'en',
  es = 'es',
  de = 'de',
  ru = 'ru',
  fr = 'fr',
  it = 'it',
}

/** ADD NEW LANG: CHECKLIST
 * [ ] add global translations (messages)
 * [ ] wpm/cpm default speed metric logic — localizedSpeedUnit()
 * [ ] dayjs
 * [ ] intro lesson
 * [ ] all the LocalizedText-type values
 * [ ] all <i18n></i18n> custom template section
 */

export type LocalizedText = Record<UserLocale, string>

// en-GB -> en
// ru -> ru
// en -> en
// fr -> en
export const resolveToSupportedLocale = (userLocale: string): UserLocale => {
  const allSupportedLocales = Object.values(UserLocale)

  if (allSupportedLocales.includes(userLocale as UserLocale)) {
    return userLocale as UserLocale
  }

  const baseLocale = userLocale.split('-')?.[0] as UserLocale
  if (allSupportedLocales.includes(baseLocale)) {
    return baseLocale
  }

  return UserLocale.en
}

const customRussianPluralRule = (choice: number, choicesLength: number) => {
  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1
  if (!teen && endsWithOne) {
    return 1
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return 0
}

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  missingWarn: false,
  fallbackWarn: false,
  pluralRules: {
    ru: customRussianPluralRule,
  },

  messages: {
    en: {
      language: 'Language',
      Language: {
        en: 'English',
        es: 'Spanish',
        de: 'German',
        ru: 'Russian',
        fr: 'French',
        pt: 'Portuguese',
        it: 'Italian',
        pl: 'Polish',
        uk: 'Ukrainian',
        tr: 'Turkish',
        nl: 'Dutch',
        sv: 'Swedish',
        hu: 'Hungarian',
        cs: 'Czech',
        da: 'Danish',
        ro: 'Romanian',
      },
      left: 'Left',
      right: 'Right',
      login: 'Log in',
      signUp: 'Sign up',
      continue: 'Continue',
      orPress: 'Or press',
      back: 'Back',
      save: 'Save',
      search: 'Search',
      and: 'and',
      wpm: 'wpm',
      cpm: 'cpm',
      loading3Dot: 'Loading...',
    },

    ru: {
      language: 'Язык',
      Language: {
        en: 'Английский',
        es: 'Испанский',
        de: 'Немецкий',
        ru: 'Русский',
        fr: 'Французский',
        pt: 'Португальский',
        it: 'Итальянский',
        pl: 'Польский',
        uk: 'Украинский',
        tr: 'Турецкий',
        nl: 'Голландский',
        sv: 'Шведский',
        hu: 'Венгерский',
        cs: 'Чешский',
        da: 'Датский',
        ro: 'Румынский',
      },
      login: 'Войти',
      signUp: 'Регистрация',
      continue: 'Продолжить',
      orPress: 'Или нажмите',
      back: 'Назад',
      save: 'Сохранить',
      search: 'Поиск',
      and: 'и',
      wpm: 'слов/мин',
      cpm: 'зн/мин',
      loading3Dot: 'Загрузка...',
    },

    es: {
      language: 'Idioma',
      Language: {
        en: 'Inglés',
        es: 'Español',
        de: 'Alemán',
        ru: 'Ruso',
        fr: 'Francés',
        pt: 'Portugués',
        it: 'Italiano',
        pl: 'Polaco',
        uk: 'Ucraniano',
        tr: 'Turco',
        nl: 'Holandés',
        sv: 'Sueco',
        hu: 'Húngaro',
        cs: 'Checo',
        da: 'Danés',
        ro: 'Rumano',
      },
      left: 'Izquierda',
      right: 'Derecha',
      login: 'Iniciar sesión',
      signUp: 'Registrarse',
      continue: 'Continuar',
      orPress: 'O presiona',
      back: 'Atrás',
      save: 'Guardar',
      search: 'Buscar',
      and: 'y',
      wpm: 'ppm',
      cpm: 'cpm',
      loading3Dot: 'Cargando...',
    },

    de: {
      language: 'Sprache',
      Language: {
        en: 'Englisch',
        es: 'Spanisch',
        de: 'Deutsch',
        ru: 'Russisch',
        fr: 'Französisch',
        pt: 'Portugiesisch',
        it: 'Italienisch',
        pl: 'Polnisch',
        uk: 'Ukrainisch',
        tr: 'Türkisch',
        nl: 'Niederländisch',
        sv: 'Schwedisch',
        hu: 'Ungarisch',
        cs: 'Tschechisch',
        da: 'Dänisch',
        ro: 'Rumänisch',
      },
      left: 'Links',
      right: 'Rechts',
      login: 'Anmelden',
      signUp: 'Registrieren',
      continue: 'Weiter',
      orPress: 'Oder drücken',
      back: 'Zurück',
      save: 'Speichern',
      search: 'Suchen',
      and: 'und',
      wpm: 'wpm',
      cpm: 'cpm',
      loading3Dot: 'Laden...',
    },

    fr: {
      language: 'Langue',
      Language: {
        en: 'Anglais',
        es: 'Espagnol',
        de: 'Allemand',
        ru: 'Russe',
        fr: 'Français',
        pt: 'Portugais',
        it: 'Italien',
        pl: 'Polonais',
        uk: 'Ukrainien',
        tr: 'Turc',
        nl: 'Néerlandais',
        sv: 'Suédois',
        hu: 'Hongrois',
        cs: 'Tchèque',
        da: 'Danois',
        ro: 'Roumain',
      },
      left: 'Gauche',
      right: 'Droite',
      login: 'Connexion',
      signUp: 'Inscription',
      continue: 'Continuer',
      orPress: 'Ou appuyez sur',
      back: 'Retour',
      save: 'Enregistrer',
      search: 'Rechercher',
      and: 'et',
      wpm: 'mots/min',
      cpm: 'car/min',
      loading3Dot: 'Chargement...',
    },

    it: {
      language: 'Lingua',
      Language: {
        en: 'Inglese',
        es: 'Spagnolo',
        de: 'Tedesco',
        ru: 'Russo',
        fr: 'Francese',
        pt: 'Portoghese',
        it: 'Italiano',
        pl: 'Polacco',
        uk: 'Ucraino',
        tr: 'Turco',
        nl: 'Olandese',
        sv: 'Svedese',
        hu: 'Ungherese',
        cs: 'Ceco',
        da: 'Danese',
        ro: 'Romeno',
      },
      left: 'Sinistra',
      right: 'Destra',
      login: 'Accedi',
      signUp: 'Registrati',
      continue: 'Continua',
      orPress: 'O premi',
      back: 'Indietro',
      save: 'Salva',
      search: 'Cerca',
      and: 'e',
      wpm: 'ppm',
      cpm: 'cpm',
      loading3Dot: 'Caricamento...',
    },
  },
})

export const switchAppLocale = (to: UserLocale) => {
  i18n.global.locale.value = to
  dayjs.locale(to)
}

export const getLangTitle = (code: string) => i18n.global.t(`Language.${code}`)
export const getNativeLangTitle = (code: string) => i18n.global.messages.value[code as UserLocale].Language[code as UserLocale]

export const localizedSpeedUnit = (): 'wpm' | 'cpm' => {
  const cpmByDefault: UserLocale[] = [UserLocale.de, UserLocale.ru]
  if (cpmByDefault.includes(i18n.global.locale.value as UserLocale)) {
    return 'cpm'
  }
  return 'wpm'
}
