<script setup lang="ts">
import { buildCourse } from '@/course/course-builder'
import { TrainerView } from '@/course/course-types'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import LessonInfoView from './LessonInfoView.vue'
import LessonTypingView from './LessonTypingView.vue'

const router = useRouter()
const userStore = useUserStore()
const courseStore = useCourseStore()
const lessonInfo = computed(() => userStore.currentLesson!)
const course = buildCourse(courseStore.current.layout)
const lesson = computed(() => course.content[lessonInfo.value.chapter][lessonInfo.value.index])

if (!userStore.hasFullAccess && !lesson.value.availableOnTrial) {
  router.push({ name: 'home' })
}
</script>

<template>
  <LessonTypingView v-if="lesson.view !== TrainerView.Info" />
  <LessonInfoView v-else />
</template>

<style lang="scss" scoped></style>
