import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/border.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/dist/tippy.css'
import type { App } from 'vue'
import VueTippy from 'vue-tippy'

const arrowSvg = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" fill="none">
    <g clip-path="url(#a)" filter="url(#b)">
      <path fill="currentColor" fill-rule="evenodd" d="M8 .6C10.4.6 12.8 7 16 7H0C3.18 7 5.6.6 8 .6Z"
        clip-rule="evenodd" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .6h16V7H0z" />
      </clipPath>
      <filter id="b" width="67.2" height="57.6" x="-25.6" y="-21.8" color-interpolation-filters="sRGB"
        filterUnits="userSpaceOnUse">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="3.2" />
        <feGaussianBlur stdDeviation="12.8" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_171_90" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset />
        <feGaussianBlur stdDeviation="1.6" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend in2="effect1_dropShadow_171_90" result="effect2_dropShadow_171_90" />
        <feBlend in="SourceGraphic" in2="effect2_dropShadow_171_90" result="shape" />
      </filter>
    </defs>
  </svg>
`
export const installTippy = (app: App) => {
  app.use(VueTippy, {
    directive: 'tippy',
    defaultProps: {
      animation: 'shift-away',
      arrow: arrowSvg,
      theme: 'ts',
      border: true,
      offset: [0, 12],
      // for debugging:
      // hideOnClick: false,
      // trigger: 'click',
    },
  })
}
