<script setup lang="ts">
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import KeyboardHighlight from '@/components/KeyboardHighlight.vue'
import KeyboardHighlightLegend from '@/components/KeyboardHighlightLegend.vue'
import Selection from '@/components/Selection.vue'
import Shortcut from '@/components/Shortcut.vue'
import { shortcutsConfig } from '@/configs/shortcuts-config'
import { buildCourse } from '@/course/course-builder'
import { getLessonTitle } from '@/course/course-lesson-titles'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { DurationComponent, durationComponentLabel } from '@/plugins/dayjs'
import { localizedSpeedUnit, UserLocale } from '@/plugins/i18n'
import { useCourseStore } from '@/stores/courseStore'
import { useTrainingStore } from '@/stores/trainingStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { Metric } from '@/types/metric-types'
import { CharTypingResult } from '@/types/typing-result/CharTypingResult'
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { t, locale } = useI18n()

const router = useRouter()

const trainingStore = useTrainingStore()
const courseStore = useCourseStore()
const userStore = useUserStore()

// NOTE: guarded in router
const lastTraining = trainingStore.lastTypingResult!

const lastTypingResult = CharTypingResult.fromFullTypingResult(lastTraining, courseStore.current.layout.os)

const course = buildCourse(courseStore.current.layout)
const completionStats = computed(() => courseStore.current.stats.completion)
const nextLesson = computed(() => completionStats.value.nextLesson())

const typingTimeHtml = computed(() => {
  const typingDuration = dayjs.duration(lastTypingResult.typingTimeMs, 'millisecond')
  // TODO
  const minutes = 1
  // const minutes = typingDuration.get('minutes')
  const seconds = Math.round(typingDuration.get('seconds') + typingDuration.get('milliseconds') / 1000)
  let result = `${seconds}<span>${durationComponentLabel[DurationComponent.s][locale.value as UserLocale]}</span>`
  return minutes ? `${minutes}<span>${durationComponentLabel[DurationComponent.m][locale.value as UserLocale]}</span> ` + result : result
})

//

const metrics: Metric[] = [Metric.Presses, Metric.Accuracy, Metric.Speed]
const metricTitles = [t('metric.presses'), t('metric.accuracy'), t('metric.speed')]
const currentMetricIndex = ref(0)
const currentMetric = computed(() => metrics[currentMetricIndex.value])

const currentRangeValues = ref(null)
const currentRangeColors = ref(null)

// shortcuts

const shortcuts = {
  continue: new KeyboardShortcut(shortcutsConfig.continue, () => {
    userStore.currentLesson = nextLesson.value

    router.push({ name: 'lesson' })
  }),
  repeat: new KeyboardShortcut(shortcutsConfig.repeat, () => {
    router.push({ name: 'lesson' })
  }),
  exit: new KeyboardShortcut(shortcutsConfig.exit, () => {
    router.push({ name: 'home' })
  }),
}

useKeyboardShortcuts(Object.values(shortcuts))
</script>

<template>
  <main class="container">
    <teleport to="#app-header">
      <AppHeader>
        <template #middle>
          <div class="training-title">
            <span
              ><span class="text-secondary">{{ t('nextLesson') }}</span
              >&nbsp;{{ getLessonTitle(course.content[nextLesson.chapter][nextLesson.index]) }}</span
            >
          </div>
        </template>
      </AppHeader>
    </teleport>

    <div class="training-result-wrapper">
      <div class="result kinda-mono">
        <div>
          {{ lastTypingResult.speed.format({ appendUnit: false }) }} <span>{{ t(localizedSpeedUnit()) }}</span>
        </div>
        <div>{{ lastTypingResult.accuracy.format({ appendUnit: false }) }}<span>%</span></div>
        <div v-html="typingTimeHtml"></div>
      </div>
      <div class="shortcuts">
        <shortcut :shortcut="shortcuts.continue" :label="t('shortcuts.next')" />
        <shortcut :shortcut="shortcuts.repeat" :label="t('shortcuts.repeat')" />
        <shortcut :shortcut="shortcuts.exit" :label="t('shortcuts.exit')" />
      </div>
    </div>

    <div class="keyboard-visualization-wrapper">
      <div class="header">
        <Selection
          :items="metricTitles"
          v-model="currentMetricIndex"
          shortcut-config-key="iterateHighlightedMetric"
          shortcut-config-key-prev="iterateHighlightedMetricPrev"
          :show-shortcut-hint="false"
        />
        <KeyboardHighlightLegend
          v-if="currentRangeValues && currentRangeColors"
          :metric="currentMetric"
          :rangeValues="currentRangeValues"
          :rangeColors="currentRangeColors"
        />
      </div>
      <KeyboardHighlight
        :metric="currentMetric"
        :last-training="true"
        v-model:rangeValues="currentRangeValues"
        v-model:rangeColors="currentRangeColors"
      />
    </div>

    <teleport to="#app-footer">
      <AppFooter>
        <template #middle>
          <div></div>
        </template>
      </AppFooter>
    </teleport>
  </main>
</template>

<i18n lang="json">
{
  "en": {
    "nextLesson": "Next:",
    "metric": {
      "presses": "Presses",
      "accuracy": "Accuracy",
      "speed": "Speed"
    },
    "shortcuts": {
      "next": "Next",
      "repeat": "Repeat",
      "exit": "Exit"
    }
  },
  "ru": {
    "nextLesson": "Дальше:",
    "metric": {
      "presses": "Нажатия",
      "accuracy": "Точность",
      "speed": "Скорость"
    },
    "shortcuts": {
      "next": "Дальше",
      "repeat": "Повторить",
      "exit": "Выйти"
    }
  },
  "es": {
    "nextLesson": "Siguiente:",
    "metric": {
      "presses": "Pulsaciones",
      "accuracy": "Precisión",
      "speed": "Velocidad"
    },
    "shortcuts": {
      "next": "Siguiente",
      "repeat": "Repetir",
      "exit": "Salir"
    }
  },
  "de": {
    "nextLesson": "Nächste:",
    "metric": {
      "presses": "Anschläge",
      "accuracy": "Genauigkeit",
      "speed": "Geschwindigkeit"
    },
    "shortcuts": {
      "next": "Weiter",
      "repeat": "Wiederholen",
      "exit": "Beenden"
    }
  },
  "fr": {
    "nextLesson": "Suivante :",
    "metric": {
      "presses": "Frappes",
      "accuracy": "Précision",
      "speed": "Vitesse"
    },
    "shortcuts": {
      "next": "Suivant",
      "repeat": "Répéter",
      "exit": "Quitter"
    }
  },
  "it": {
    "nextLesson": "Prossima:",
    "metric": {
      "presses": "Battute",
      "accuracy": "Precisione",
      "speed": "Velocità"
    },
    "shortcuts": {
      "next": "Avanti",
      "repeat": "Ripeti",
      "exit": "Esci"
    }
  }
}
</i18n>

<style lang="scss" scoped>
// header
.training-title {
  font-weight: 500;
  height: var(--header-inner-height);
  font-size: var(--fz-sm);
  display: grid;
  place-items: center;
  width: auto;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
}

.training-result-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 250px;

  @media (max-width: 1250px) {
    margin-bottom: 225px;
  }

  .result {
    display: flex;
    gap: 2rem;
    padding-bottom: 3rem;
    font-size: var(--fz-h1);
    border-bottom: 1px solid var(--c-divider);

    :deep(span) {
      color: var(--c-text-tertiary);
      opacity: 0.6;
    }
  }

  .shortcuts {
    display: flex;
    gap: 1.5rem;
    padding: var(--s-md) 0.5rem 0;
  }
}

.keyboard-visualization-wrapper {
  position: absolute;
  width: calc(100vw - 440px);
  min-width: calc(var(--min-viewport-width) - 400px);
  max-width: 800px;
  bottom: calc(-1 * var(--footer-height) + var(--grid-cell));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: var(--s-md);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
</style>
