<script setup lang="ts">
import { shortcutsConfig } from '@/configs/shortcuts-config'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { useShortcutStore } from '@/stores/shortcutStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed, watch } from 'vue'

type Props = {
  modalId: string
  maxWidth?: number
}
const props = withDefaults(defineProps<Props>(), {
  maxWidth: 500,
})

const model = defineModel<boolean>()

const shortcutStore = useShortcutStore()

watch(
  model,
  (newValue) => {
    if (newValue) {
      shortcutStore.activeModalId = props.modalId
    } else if (shortcutStore.activeModalId === props.modalId) {
      shortcutStore.activeModalId = null
    }
  },
  { immediate: true },
)

const maxWidthPx = computed(() => `${props.maxWidth}px`)

const hideModal = () => {
  model.value = false
}

const onModalAreaClick = (event: Event) => {
  if (event.target === event.currentTarget) {
    hideModal()
  }
}

const discardShortcut = new KeyboardShortcut(
  shortcutsConfig.discard,
  () => {
    hideModal()
  },
  props.modalId,
)

useKeyboardShortcuts([discardShortcut])
</script>

<template>
  <div class="modal" v-show="model" @click="onModalAreaClick">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(body) {
  --c-modal-backdrop: rgb(var(--c-surface-rgb) / 0.8);
}
:global(body.dark) {
  --c-modal-backdrop: rgb(var(--c-background-rgb) / 0.9);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--c-modal-backdrop);
  backdrop-filter: blur(8px);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;

  .content {
    padding: var(--s-xl);
    border-radius: var(--br-xl);
    max-width: v-bind('maxWidthPx');
    background-color: var(--c-modal-background);
    // box-shadow: var(--box-shadow-modal);

    .title {
      font-size: var(--fz-h6);
      font-weight: 600;
      margin-bottom: var(--s-md);
    }

    .description {
      font-size: var(--fz-sm);
      margin-bottom: var(--s-lg);
    }

    .actions {
      display: flex;
      gap: var(--s-md);
      align-items: center;

      .shortcut-hint {
        font-size: var(--fz-sm);
        color: var(--c-text-secondary);
      }
    }
  }
}
</style>
