import Hotjar from '@hotjar/browser'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getCookieSettingsFromLocalStorage } from '../stores/cookieStore'

// limits: https://support.google.com/analytics/answer/9267744?hl=en

// NOTE: keep CustomDimension and CustomMetric in sync with custom definitions in google analytics
// https://analytics.google.com/analytics/web/?pli=1#/a307583237p433459538/admin/customdefinitions/hub
// specified string is both event parameter and dimension name in google analytics

// string values; limited to 50 items in GA
type CustomDimension = 'type' | 'reason' | 'result' | 'layout_id' | 'number' | 'value'

// number values, could be aggregated; limited to 50 items in GA
type CustomMetric = 'speed_wpm' | 'accuracy_pct' | 'num_speed_wpm' | 'num_accuracy_pct'

// custom dimensions/metrics and built-in params
type EventParamName = CustomDimension | CustomMetric | 'method'

type EventParamValue = string | number

type EventParams = {
  [key in EventParamName]?: EventParamValue
}

// for pure type checking; typing whole config object breaks intellisense
const params = (...p: EventParamName[]) => p

// max len: 40 chars
//1234567890123456789012345678901234567890
export const eventsConfig = {
  // Reports:

  // ACTIVE USERS & TRAINING TYPE BY POPULARITY
  // calc method: completed 1 training in last 7 days

  // NOTE: temporary disabled
  training_complete: params('type', 'speed_wpm', 'accuracy_pct'), // for 'type' we use shortcut key

  // COURSE SETUP
  // flow starts from `click_get_started` event
  course_setup_language: params('value'), // value:langCode  (setup begin)
  course_setup_format_auto: params('value'), // value:iso/ans
  course_setup_format_manual: params('value'), // value:iso/ans
  course_setup_layout_auto: params('layout_id'), //  (setup end)
  course_setup_layout_manual: params('layout_id'), //  (setup end)

  first_training_complete: params(),
  last_training_complete: params(),
  // str event: `click_buy`
  payment_success: params(),
} as const

// open/close: modals
// click: buttons, links
type StringEventName = `open_${string}` | `close_${string}` | `click_${string}`

type EventName = keyof typeof eventsConfig | StringEventName

export const logAnalyticsEvent = (event: EventName, params?: EventParams) => {
  const analyticsAllowed = getCookieSettingsFromLocalStorage().isAnalyticsAllowed
  if (import.meta.env.DEV) {
    console.log(analyticsAllowed ? 'Mock::logAnalyticsEvent:' : 'Mock:logAnalyticsEvent[blocked]', event, params ?? '')
    return
  }

  if (!analyticsAllowed) return

  const analytics = getAnalytics()
  // as string needed here because otherwise TS complains for standard event names like 'sign_up' being in config
  logEvent(analytics, event as string, params)
}

export const installHotjar = () => {
  if (import.meta.env.DEV) {
    console.log('Mock::installHotjar()')
  } else {
    const siteId = 5025462
    const hotjarVersion = 6
    Hotjar.init(siteId, hotjarVersion)
  }
}
