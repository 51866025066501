// HSL color type

export type HSL = {
  hue: number
  saturation: number
  lightness: number
  opacity: number
}

export const hsl = (hue: number, saturation: number, lightness: number, opacity: number = 100): HSL => {
  return { hue, saturation, lightness, opacity }
}

export const hslToString = (hsl: HSL): string => {
  return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%, ${hsl.opacity}%)`
}

// RGB color type

export type RGB = {
  r: number
  g: number
  b: number
}

// Shared

export const hslToRgb = (hsl: HSL): RGB => {
  const h = hsl.hue / 360
  const s = hsl.saturation / 100
  const l = hsl.lightness / 100
  let r, g, b

  if (s === 0) {
    r = g = b = l // Achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number): number => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return { r: r * 255, g: g * 255, b: b * 255 }
}

export const getContrastTextColor = (background: HSL): HSL => {
  const rgb = hslToRgb(background)
  const relativeLuminance = (val: number): number => {
    val /= 255
    return val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4)
  }

  const luminance = 0.2126 * relativeLuminance(rgb.r) + 0.7152 * relativeLuminance(rgb.g) + 0.0722 * relativeLuminance(rgb.b)
  const lightnessThreshold = 0.179 // Adjust as needed

  return luminance > lightnessThreshold ? hsl(0, 0, 0, 100) : hsl(0, 0, 100, 100)
}
