<script setup lang="ts">
import Button from '@/components/Button.vue'
import UserMenu from '@/components/UserMenu.vue'
import { signInWithGoogle } from '@/plugins/firebase/auth'
import { useUserStore } from '@/stores/userStore'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink, useRoute } from 'vue-router'
import DarkLightImage from './DarkLightImage.vue'
import LanguageMenu from './LanguageMenu.vue'

type Props = {
  divided?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  divided: false,
})

const { t } = useI18n()
const route = useRoute()
const userStore = useUserStore()

const trainingStatsExists = computed(() => userStore.statsExists)

const logoUrl = computed(() => {
  if (route.name === 'landing') {
    return '/'
  } else if (route.name === 'courseSetup' && !trainingStatsExists.value) {
    return '/'
  }
  return '/home'
})
</script>

<template>
  <nav :class="{ divided }">
    <div class="left-wrapper">
      <slot name="left">
        <div class="logo-wrapper">
          <RouterLink :to="logoUrl" class="logo">
            <img src="/src/assets/img/logo-beta/keyboard.png" alt="Typing School" />
            <DarkLightImage path="logo-beta" ext="svg" alt="Typing School" />
          </RouterLink>
        </div>
      </slot>
    </div>

    <slot name="middle">
      <!-- <div v-if="route.meta.title" class="view-title">{{ route.meta.title }}</div> -->
    </slot>

    <div class="right-wrapper">
      <slot name="right">
        <div style="display: flex; align-items: center; gap: 0.5rem">
          <Button variant="secondary" v-if="route.name === 'home' && !userStore.authData" @click="signInWithGoogle">
            {{ t('signUp') }}
          </Button>

          <UserMenu v-if="route.name === 'home'" @login="signInWithGoogle" />
          <LanguageMenu v-if="route.name === 'landing'" />

          <Button variant="secondary"
            v-if="route.name === 'landing' || route.name === 'courseSetup' && !userStore.authData"
            @click="signInWithGoogle"
          >
            {{ t('login') }}
          </Button>
        </div>
      </slot>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  height: var(--header-height, 3.75rem);
  width: 100%;
  min-width: var(--min-viewport-inner-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: var(--c-background);

  &.divided {
    border-bottom: 1px solid var(--c-divider);
  }
}

.left-wrapper {
  flex: 3;

  .view-title {
    font-weight: 500;
    height: var(--header-inner-height);
    font-size: var(--fz-md);
  }
}

.logo-wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;

  .logo {
    text-decoration: none;
    outline: none;
    font-size: 0;
    :deep(img) {
      height: 2rem;
    }
  }
}

.right-wrapper {
  flex: 3;
  display: flex;
  justify-content: flex-end;
}
</style>
