import { localStorageKey } from '@/helpers/main-config'
import { useLocalStorage, usePreferredColorScheme } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'

export enum Theme {
  Dark = 'Dark',
  Light = 'Light',
  Auto = 'Auto',
}

export type ToastData = {
  text: string
  emoji: string
}

export const useAppStore = defineStore('app', () => {
  // THEME

  const theme = useLocalStorage(localStorageKey.theme, Theme.Dark)

  const preferredColor = usePreferredColorScheme()

  const isDarkTheme = computed(() => {
    return theme.value === Theme.Dark || (theme.value === Theme.Auto && preferredColor.value === 'dark')
  })

  watch(
    isDarkTheme,
    (to) => {
      if (to) {
        document.body.classList.add('dark')
      } else {
        document.body.classList.remove('dark')
      }
    },
    { immediate: true },
  )

  // TOAST

  const toastData = ref<null | ToastData>(null)
  const cursorToastData = ref<null | ToastData>(null)

  const dismissToast = (type: 'top' | 'cursor') => {
    const data = type === 'top' ? toastData : cursorToastData
    data.value = null
  }

  const showToast = async (type: 'top' | 'cursor', payload: ToastData) => {
    const data = type === 'top' ? toastData : cursorToastData

    if (data.value !== null) {
      data.value = null
      await nextTick()
    }

    data.value = payload
    setTimeout(() => {
      dismissToast(type)
    }, 3000)
  }

  return {
    theme,
    isDarkTheme,
    showToast,
    dismissToast,
    toastData,
    cursorToastData,
  }
})
