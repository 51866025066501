<script setup lang="ts">
import { shortcutsConfig, shortcutsHintConfig } from '@/configs/shortcuts-config'
import useKeyboardShortcuts from '@/helpers/composables/useKeyboardShortcuts'
import { MAX_ACTIVE_LAYOUTS } from '@/helpers/main-config'
import { getLangTitle } from '@/plugins/i18n'
import { useCourseStore } from '@/stores/courseStore'
import { useUserStore } from '@/stores/userStore'
import { KeyboardShortcut } from '@/types/KeyboardShortcut'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import Emoji from './Emoji.vue'
import TheFeedbackModal from './TheFeedbackModal.vue'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const courseStore = useCourseStore()

const currShortcutHint = computed(() => (route.name ? shortcutsHintConfig[route.name as keyof typeof shortcutsHintConfig] : null))

const showAndHandleLayouts = computed(() => ['home'].includes(route.name as string))
const showAndHandleFeedback = computed(() => ['home'].includes(route.name as string))

// add layout
const addLayout = () => {
  if (!showAndHandleLayouts.value) {
    return
  }
  if (courseStore.courses.length < MAX_ACTIVE_LAYOUTS) {
    router.push({ name: 'courseSetup' })
  }
}

// layouts
const layouts = computed(() => courseStore.courses)

// delete layout
const deleteLayout = () => {
  const currentLayout = courseStore.current
  if (!currentLayout) {
    return
  }
  if (!window.confirm(t('areYouSure', [`${getLangTitle(currentLayout.definition.languageCode)} (${currentLayout.definition.title})`]))) {
    return
  }

  courseStore.deleteCurrentLayout()
}

// feedback modal
const userStore = useUserStore()

const feedbackModal = ref(false)
const onFeedbackClick = (event: MouseEvent) => {
  if (userStore.authData?.email) {
    feedbackModal.value = true
    event.preventDefault()
  }
}

// shortcuts

const shortcuts = {
  feedbackModal: new KeyboardShortcut(shortcutsConfig.aboutOrFeedback, () => {
    if (!showAndHandleFeedback.value) return
    feedbackModal.value = true
  }),
  addLayout: new KeyboardShortcut(shortcutsConfig.addLayout, addLayout),
}

if (route.name !== 'lesson') {
  useKeyboardShortcuts(Object.values(shortcuts))
}
</script>

<template>
  <footer>
    <div class="left-wrapper">
      <slot name="left">
        <!-- <ShortcutHint v-if="currShortcutHint" :shortcuts="currShortcutHint()" /> -->
        <slot name="middle">
          <div
            class="layouts"
            v-if="showAndHandleLayouts && courseStore.courses.length"
            :class="{ withAddBtn: courseStore.courses.length < MAX_ACTIVE_LAYOUTS }"
          >
            <div
              class="layout-item"
              v-for="(item, index) in layouts"
              :key="index"
              :class="{ active: courseStore.current && item.definition.equal(courseStore.current.definition) }"
              @click="courseStore.setCurrentLayout(index)"
            >
              <Emoji :name="`flag-${item.definition.languageCode}`" type="separate" />
              <div class="vl"></div>
              <div class="layout">
                <svg class="icon">
                  <use :href="'#icon-keyboard'" />
                </svg>
                <span>{{ item.definition.title }}</span>
              </div>
            </div>

            <div class="btn-on-hover delete-btn" v-tippy="{ content: t('deleteCurrentCourse'), theme: 'ts danger' }" @click="deleteLayout">
              <i class="fi fi-sr-trash"></i>
            </div>
            <div
              class="btn-on-hover add-btn"
              v-tippy="{ content: t('addNewCourse') }"
              v-if="courseStore.courses.length < MAX_ACTIVE_LAYOUTS"
              @click="addLayout"
            >
              <i class="fi fi-br-plus-small"></i>
            </div>
          </div>
        </slot>
      </slot>
    </div>

    <div class="middle-wrapper"></div>

    <div class="right-wrapper">
      <slot name="right"> </slot>
    </div>
  </footer>

  <TheFeedbackModal v-model="feedbackModal" />
</template>

<i18n lang="json">
{
  "en": {
    "deleteCurrentCourse": "Delete current course",
    "addNewCourse": "Add new course",
    "areYouSure": "Are you sure you want to permanently delete the \"{0}\" course along with all its statistics? This action cannot be undone."
  },
  "ru": {
    "deleteCurrentCourse": "Удалить текущий курс",
    "addNewCourse": "Добавить курс",
    "areYouSure": "Вы уверены что хотите безвозвратно удалить курс \"{0}\" со всей статистикой? Отменить действие будет невозможно."
  },
  "es": {
    "deleteCurrentCourse": "Eliminar curso actual",
    "addNewCourse": "Añadir nuevo curso",
    "areYouSure": "¿Estás seguro de que quieres eliminar permanentemente el curso \"{0}\" junto con todas sus estadísticas? Esta acción no se puede deshacer."
  },
  "de": {
    "deleteCurrentCourse": "Aktuellen Kurs löschen",
    "addNewCourse": "Neuen Kurs hinzufügen",
    "areYouSure": "Sind Sie sicher, dass Sie den Kurs \"{0}\" samt aller Statistiken unwiderruflich löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
  },
  "fr": {
    "deleteCurrentCourse": "Supprimer le cours actuel",
    "addNewCourse": "Ajouter un nouveau cours",
    "areYouSure": "Êtes-vous sûr de vouloir supprimer définitivement le cours \"{0}\" ainsi que toutes ses statistiques ? Cette action est irréversible."
  },
  "it": {
    "deleteCurrentCourse": "Elimina corso attuale",
    "addNewCourse": "Aggiungi nuovo corso",
    "areYouSure": "Sei sicuro di voler eliminare permanentemente il corso \"{0}\" insieme a tutte le sue statistiche? Questa azione non può essere annullata."
  }
}
</i18n>

<style lang="scss" scoped>
footer {
  height: var(--footer-height);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: var(--grid-cell);
  // border-top: 1px solid var(--c-divider);
  // position: relative;
  // background-color: var(--c-background);
}

// .left-wrapper {
//   flex: 1;
// }

.left-wrapper {
  flex: 4.5;
  display: flex;
  // align-items: center;
  // justify-content: center;

  .layouts {
    display: flex;
    align-items: center;
    gap: var(--s-sm);

    .layout-item {
      display: flex;
      align-items: center;
      border: 1px solid var(--c-divider);
      border-radius: var(--br-md);
      height: var(--footer-inner-height);
      font-size: var(--fz-sm);
      padding: 0 var(--s-md);
      gap: 0.75rem;
      cursor: pointer;

      .emoji {
        position: relative;
        top: 1px;
      }

      .vl {
        height: 100%;
        width: 1px;
        background-color: var(--c-divider);
      }

      .layout {
        display: flex;
        gap: 0.25em;
        align-items: center;

        .icon {
          width: 1rem;
          height: 1rem;
        }
      }

      .values {
        display: flex;
        // justify-content: space-between;
        gap: var(--s-sm);
        font-size: var(--fz-md);
        font-weight: 500;
        font-feature-settings:
          'tnum' on,
          'zero' on;
      }

      &.active {
        background-color: var(--c-surface);
      }
    }

    .btn-on-hover {
      height: var(--footer-inner-height);
      width: var(--footer-inner-height);
      border-radius: var(--br-md);
      border: 1px solid var(--c-divider);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      transition: all 0.2s ease-out;

      i {
        display: inline-flex;
      }

      &:hover {
        background-color: var(--c-surface);
      }

      &.delete-btn {
        font-size: var(--fz-xs);
        color: var(--c-text-danger);
        border-color: var(--c-danger-border);

        &:hover {
          background-color: var(--c-danger-bg);
        }
      }
    }

    // margin-left: calc((var(--s-sm) + var(--footer-inner-height)) * 1);
    // &.withAddBtn {
    //   margin-left: calc((var(--s-sm) + var(--footer-inner-height)) * 2);
    // }

    &:hover {
      .btn-on-hover {
        opacity: 1 !important;
      }
    }
  }
}

.right-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: var(--fz-sm);
}
</style>
