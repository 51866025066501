<script setup lang="ts">
import { useUserStore } from '@/stores/userStore'
import { nextTick, ref, watch } from 'vue'
import Button from './Button.vue'
import Modal from './Modal.vue'
import Toast from './Toast.vue'

const model = defineModel<boolean>()

const feedback = ref('')
const feedbackInput = ref<HTMLTextAreaElement | null>(null)
const toast = ref<InstanceType<typeof Toast>>()

watch(model, (newVal) => {
  if (newVal) {
    nextTick(() => {
      feedbackInput.value?.focus()
    })
  }
})

const userStore = useUserStore()

const onSendFeedback = () => {
  // if (!feedback.value) {
  //   return
  // }
  // addFeedback(userStore.authData?.email, feedback.value)
  // axios
  //   .post(cloudFunctionUrl('feedback'), {
  //     email: userStore.authData?.email,
  //     message: feedback.value,
  //   })
  //   .then(() => {
  //     console.log('Feedback sent')
  //   })
  //   .catch((error) => {
  //     console.error('Error sending feedback', error)
  //   })
  // model.value = false
  // feedback.value = ''
  // toast.value?.showToast('Thank you for your feedback!')
}
</script>

<template>
  <Modal modal-id="feedback" v-model="model">
    <div class="modal-content">
      <div class="title">Support / feedback</div>
      <div class="description">
        <p v-if="userStore.authData?.email">
          We will answer you to <b> {{ userStore.authData?.email }} </b>. If you would like to use other address, please contact us directly via
          <a href="mailto:hello@typing.school">hello@typing.school</a>
        </p>
      </div>

      <textarea @keydown.stop ref="feedbackInput" autofocus v-model="feedback"></textarea>

      <div class="actions">
        <Button size="md" @click="onSendFeedback()" :disabled="!feedback">Submit request</Button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 420px;

  .title {
    font-size: var(--fz-h3);
    font-weight: 600;
    margin-bottom: var(--s-sm);
  }

  .description {
    font-size: var(--fz-sm);
    margin-bottom: var(--s-xl);
    color: var(--c-text-secondary);
  }

  textarea {
    width: 100%;
    height: 12rem;
    padding: var(--grid-cell);
    border: 2px solid var(--c-secondary-border);
    border-radius: var(--br-lg);
    font-size: var(--fz-md);
    resize: none;
    background-color: var(--background);
    outline: none;
    color: var(--c-text-primary);
    font-family: var(--ff-main);

    &:focus {
      border: 2px solid var(--c-primary);
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: var(--s-md);
    // align-items: center;
    margin-top: var(--s-md);

    .shortcut-hint {
      font-size: var(--fz-sm);
      color: var(--c-text-secondary);
    }
  }
}
</style>
