import LandingView from '@/views/LandingView.vue'
import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useCourseStore } from './stores/courseStore'
import { useRouterStore } from './stores/routerStore'
import { useTrainingStore } from './stores/trainingStore'
import { useUserStore } from './stores/userStore'
import CourseSetupView from './views/CourseSetupView.vue'
import HomeView from './views/HomeView.vue'
import LessonView from './views/LessonView.vue'
import PaymentSuccess from './views/PaymentSuccess.vue'
import ResultView from './views/ResultView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // direct access allowed
    {
      path: '/:locale?',
      name: 'landing',
      component: LandingView,
      meta: { directAccess: true },
      // customHeader/Footer is problematic to use here
      // probably because of `<div v-if="!showLoader"...>` in App.vue
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('./views/PrivacyView.vue'),
      meta: { directAccess: true, title: 'Privacy Policy | Typing School' },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/TermsView.vue'),
      meta: { directAccess: true, title: 'Terms of Use | Typing School' },
    },

    // onboarding
    {
      path: '/setup',
      name: 'courseSetup',
      component: CourseSetupView,
      meta: { customHeader: true, customFooter: true, title: 'Setup | Typing School' },
    },

    // home
    {
      path: '/home',
      name: 'home',
      component: HomeView,
      meta: { customHeader: true, customFooter: true, title: 'Home | Typing School' },
    },
    {
      path: '/lesson',
      name: 'lesson',
      meta: { customHeader: true, customFooter: true, title: 'Lesson | Typing School' },
      component: LessonView,
      beforeEnter: () => {
        const userStore = useUserStore()
        if (!userStore.currentLesson) {
          return { name: 'home' }
        }
        return true
      },
    },
    {
      path: '/result',
      name: 'result',
      meta: { customHeader: true, customFooter: true, title: 'Result | Typing School' },
      component: ResultView,
      beforeEnter: () => {
        const trainingStore = useTrainingStore()
        const lastTraining = trainingStore.lastTypingResult
        if (!lastTraining) {
          return { name: 'home' }
        }
        return true
      },
    },
    {
      path: '/payment/success/:paymentId',
      name: 'paymentSuccess',
      component: PaymentSuccess,
      meta: { customHeader: true, customFooter: true },
    },
  ],
})

router.beforeEach(async (to, from) => {
  nextTick(() => {
    if (typeof to.meta.title === 'string') {
      document.title = to.meta.title
    }
  })

  if (!!to.meta.directAccess) {
    return true
  }

  const routerStore = useRouterStore()
  const courseStore = useCourseStore()
  const userStore = useUserStore()

  const waitForUserStore = () => {
    return new Promise((resolve) => {
      if (userStore.initialized) {
        resolve('Layout store is initialized')
      } else {
        userStore.waitForInit(() => resolve('Layout store is initialized'))
      }
    })
  }

  await waitForUserStore()

  if (!courseStore.courses.length) {
    if (to.name === 'courseSetup') {
      return true
    } else {
      routerStore.nextRoute = to
      return { name: 'courseSetup' }
    }
  }

  return true
})

export default router
