import type { KeyCode, TypeableKeyCode } from './KeyCode'

export enum Finger {
  Little = 'Little',
  Ring = 'Ring',
  Middle = 'Middle',
  Index = 'Index',
  Thumb = 'Thumb',
}

export enum Hand {
  Left = 'Left',
  Right = 'Right',
}

export enum Row {
  Top = 'Top',
  Home = 'Home',
  Bottom = 'Bottom',
}

export type Mapping = Map<Hand, Map<Finger, KeyCode[]>>

export class FingerMapping {
  constructor(public mapping: Mapping) {}

  getKeys(hand: Hand, finger: Finger): KeyCode[] {
    return this.mapping.get(hand)?.get(finger) ?? []
  }

  getFinger(key: KeyCode): { hand: Hand; finger: Finger } | undefined {
    for (const [hand, fingers] of this.mapping) {
      for (const [finger, keys] of fingers) {
        if (keys.includes(key)) {
          return { hand, finger }
        }
      }
    }
  }
}

// configs

export const homeRowMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, ['KeyA']],
        [Finger.Ring, ['KeyS']],
        [Finger.Middle, ['KeyD']],
        [Finger.Index, ['KeyF']],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, ['KeyJ']],
        [Finger.Middle, ['KeyK']],
        [Finger.Ring, ['KeyL']],
        [Finger.Little, ['Semicolon']],
      ]),
    ],
  ]),
)

export const threeRowsMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, ['KeyQ', 'KeyA', 'KeyZ']],
        [Finger.Ring, ['KeyW', 'KeyS', 'KeyX']],
        [Finger.Middle, ['KeyE', 'KeyD', 'KeyC']],
        [Finger.Index, ['KeyR', 'KeyF', 'KeyV', 'KeyT', 'KeyG', 'KeyB']],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, ['KeyY', 'KeyH', 'KeyN', 'KeyU', 'KeyJ', 'KeyM']],
        [Finger.Middle, ['KeyI', 'KeyK', 'Comma']],
        [Finger.Ring, ['KeyO', 'KeyL', 'Period']],
        [Finger.Little, ['KeyP', 'Semicolon', 'Slash']],
      ]),
    ],
  ]),
)

export const threeRowsMappingPerRow: Record<Row, TypeableKeyCode[]> = {
  [Row.Top]: ['KeyQ', 'KeyW', 'KeyE', 'KeyR', 'KeyT', 'KeyY', 'KeyU', 'KeyI', 'KeyO', 'KeyP'],
  [Row.Home]: ['KeyA', 'KeyS', 'KeyD', 'KeyF', 'KeyG', 'KeyH', 'KeyJ', 'KeyK', 'KeyL', 'Semicolon'],
  [Row.Bottom]: ['KeyZ', 'KeyX', 'KeyC', 'KeyV', 'KeyB', 'KeyN', 'KeyM', 'Comma', 'Period', 'Slash'],
}

// CORE: without nums and meta keys
export const coreMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, ['Backquote', 'Tab', 'CapsLock', 'ShiftLeft', 'KeyQ', 'KeyA', 'KeyZ', 'IntlBackslash']],
        [Finger.Ring, ['KeyW', 'KeyS', 'KeyX']],
        [Finger.Middle, ['KeyE', 'KeyD', 'KeyC']],
        [Finger.Index, ['KeyR', 'KeyF', 'KeyV', 'KeyT', 'KeyG', 'KeyB']],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, ['KeyY', 'KeyH', 'KeyN', 'KeyU', 'KeyJ', 'KeyM']],
        [Finger.Middle, ['KeyI', 'KeyK', 'Comma']],
        [Finger.Ring, ['KeyO', 'KeyL', 'Period']],
        [
          Finger.Little,
          ['KeyP', 'Semicolon', 'Slash', 'BracketLeft', 'Quote', 'BracketRight', 'Backslash', 'Minus', 'Equal', 'Backspace', 'ShiftRight', 'Enter'],
        ],
      ]),
    ],
  ]),
)

// NUMS
// 1. optimized mapping
export const optimizedNumsMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, ['Digit1', 'Digit2']],
        [Finger.Ring, ['Digit3']],
        [Finger.Middle, ['Digit4']],
        [Finger.Index, ['Digit5', 'Digit6']],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, ['Digit7', 'Digit8']],
        [Finger.Middle, ['Digit9']],
        [Finger.Ring, ['Digit0']],
      ]),
    ],
  ]),
)
// 2. logical mapping
export const logicalNumsMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, ['Digit1']],
        [Finger.Ring, ['Digit2']],
        [Finger.Middle, ['Digit3']],
        [Finger.Index, ['Digit4', 'Digit5']],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, ['Digit6', 'Digit7']],
        [Finger.Middle, ['Digit8']],
        [Finger.Ring, ['Digit9']],
        [Finger.Little, ['Digit0']],
      ]),
    ],
  ]),
)

// WHOLE
// 1. optimized
export const optimizedMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, [...coreMapping.getKeys(Hand.Left, Finger.Little), ...optimizedNumsMapping.getKeys(Hand.Left, Finger.Little)]],
        [Finger.Ring, [...coreMapping.getKeys(Hand.Left, Finger.Ring), ...optimizedNumsMapping.getKeys(Hand.Left, Finger.Ring)]],
        [Finger.Middle, [...coreMapping.getKeys(Hand.Left, Finger.Middle), ...optimizedNumsMapping.getKeys(Hand.Left, Finger.Middle)]],
        [Finger.Index, [...coreMapping.getKeys(Hand.Left, Finger.Index), ...optimizedNumsMapping.getKeys(Hand.Left, Finger.Index)]],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, [...coreMapping.getKeys(Hand.Right, Finger.Index), ...optimizedNumsMapping.getKeys(Hand.Right, Finger.Index)]],
        [Finger.Middle, [...coreMapping.getKeys(Hand.Right, Finger.Middle), ...optimizedNumsMapping.getKeys(Hand.Right, Finger.Middle)]],
        [Finger.Ring, [...coreMapping.getKeys(Hand.Right, Finger.Ring), ...optimizedNumsMapping.getKeys(Hand.Right, Finger.Ring)]],
        [Finger.Little, [...coreMapping.getKeys(Hand.Right, Finger.Little), ...optimizedNumsMapping.getKeys(Hand.Right, Finger.Little)]],
      ]),
    ],
  ]),
)
// 2. logical
export const logicalMapping: FingerMapping = new FingerMapping(
  new Map<Hand, Map<Finger, KeyCode[]>>([
    [
      Hand.Left,
      new Map([
        [Finger.Little, [...coreMapping.getKeys(Hand.Left, Finger.Little), ...logicalNumsMapping.getKeys(Hand.Left, Finger.Little)]],
        [Finger.Ring, [...coreMapping.getKeys(Hand.Left, Finger.Ring), ...logicalNumsMapping.getKeys(Hand.Left, Finger.Ring)]],
        [Finger.Middle, [...coreMapping.getKeys(Hand.Left, Finger.Middle), ...logicalNumsMapping.getKeys(Hand.Left, Finger.Middle)]],
        [Finger.Index, [...coreMapping.getKeys(Hand.Left, Finger.Index), ...logicalNumsMapping.getKeys(Hand.Left, Finger.Index)]],
      ]),
    ],
    [
      Hand.Right,
      new Map([
        [Finger.Index, [...coreMapping.getKeys(Hand.Right, Finger.Index), ...logicalNumsMapping.getKeys(Hand.Right, Finger.Index)]],
        [Finger.Middle, [...coreMapping.getKeys(Hand.Right, Finger.Middle), ...logicalNumsMapping.getKeys(Hand.Right, Finger.Middle)]],
        [Finger.Ring, [...coreMapping.getKeys(Hand.Right, Finger.Ring), ...logicalNumsMapping.getKeys(Hand.Right, Finger.Ring)]],
        [Finger.Little, [...coreMapping.getKeys(Hand.Right, Finger.Little), ...logicalNumsMapping.getKeys(Hand.Right, Finger.Little)]],
      ]),
    ],
  ]),
)
