// NOTE(dp): key index used to code typos objects, so:
// ! do not reorder
// ! add new items to the bottom
// ! keep total size < 100
export const typeableKeyCodes = [
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'KeyQ',
  'KeyW',
  'KeyE',
  'KeyR',
  'KeyT',
  'KeyY',
  'KeyU',
  'KeyI',
  'KeyO',
  'KeyP',
  'BracketLeft',
  'BracketRight',
  'Backslash',
  'KeyA',
  'KeyS',
  'KeyD',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyJ',
  'KeyK',
  'KeyL',
  'Semicolon',
  'Quote',
  'KeyZ',
  'KeyX',
  'KeyC',
  'KeyV',
  'KeyB',
  'KeyN',
  'KeyM',
  'Comma',
  'Period',
  'Slash',
  'Space',
  'IntlBackslash', // iso layout
  // 'IntRo', // brazil
  // 'IntlYen', // japan
] as const

export type TypeableKeyCode = (typeof typeableKeyCodes)[number]

export const modifierKeyCodes = ['CapsLock', 'ShiftLeft', 'ShiftRight', 'AltLeft', 'AltRight', 'AltGraph'] as const
export type ModifierKeyCode = (typeof modifierKeyCodes)[number]

export const funcKeyCodes = [
  'Backspace',
  'Enter',
  'ShiftLeft',
  'ShiftRight',
  'ControlLeft',
  'ControlRight',
  'MetaLeft',
  'MetaRight',
  'AltLeft',
  'AltRight',
  'AltGraph',
  'ArrowLeft',
  'ArrowDown',
  'ArrowUp',
  'ArrowRight',
  'CapsLock',
  'Tab',
  'Escape',
] as const
export type FuncKeyCode = (typeof funcKeyCodes)[number]

export type KeyCode = TypeableKeyCode | FuncKeyCode

export const isTypeable = (keyCode: KeyCode): keyCode is TypeableKeyCode => {
  return typeableKeyCodes.includes(keyCode as TypeableKeyCode)
}
